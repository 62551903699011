import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import StarIcon from '@material-ui/icons/Star';
import ReceiptIcon from '@material-ui/icons/Receipt';
import Button from "@material-ui/core/Button";
import CreateIcon from "@material-ui/icons/Create";
import { Grid, Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { portal } from "../../services/Stripe";
import { showOnboardingDialog } from "../../store/features/dialogs";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 64,
    margin: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  card: {
    color: theme.palette.text.primary,
  },
  button: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
}));

const JobHeader = () => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const { total_jobs, remaining_jobs, renew_date, level } = useSelector(
    (state) => state.limits
  )
  const { jobsEmpty } = useSelector((state) => state.jobs)

  useEffect(() => {
    if (jobsEmpty === 1) {
      dispatch(showOnboardingDialog())
    }
  }, [jobsEmpty, dispatch])

  return (
    <Box className={classes.root}>
      <Grid item xs container spacing={2}>
        <Grid
          item
          xs={12}
          md={8}
          className={classes.card}  
        >
              <Button
                id="create-job-button"
                variant="contained"
                size="small"
                color="default"
                className={classes.button}
                startIcon={<CloudUploadIcon style={{ color: "#1F5C86" }} />}
                to="/jobs/new"
                component={Link}
                disabled={remaining_jobs === 0}
              >
                Upload Existing XMLX Files
              </Button>
              <Button
                id="create-design-button"
                variant="contained"
                size="small"
                color="default"
                to="/designs/new"
                className={classes.button}
                startIcon={<CreateIcon style={{ color: "#1F5C86" }} />}
                component={Link}
                disabled={remaining_jobs === 0}
              >
                Create New Design
              </Button>
              {level === 1 && (
                <Button
                  className={classes.button}
                  variant="contained"
                  size="small"
                  color="default"
                  startIcon={<StarIcon style={{ color: "#1F5C86" }} />}
                  to='/stripe/preview'
                  component={Link}
                >
                  Upgrade Account
                </Button>
              )}
              {level > 1 && (
                <Button
                  className={classes.button}
                  variant="contained"
                  size="small"
                  color="default"
                  startIcon={<ReceiptIcon style={{ color: "#1F5C86" }} />}
                  onClick={() => {
                    portal()
                  }}
                >
                  Billing Portal
                </Button>
              )}
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            className={classes.card}
          >
            <ul
              style={{
                listStyle: "none",
                padding: 6,
                flexGrow: 1,
                color: "grey",
                fontSize: 13,
              }}
            >
              <li style={{ listStyle: "none", color: 'black'}}>
                Need support? Contact us: <a href="mailto:support@daisy.ai">support@daisy.ai</a>
              </li>
              <li style={{ listStyle: "none", display: "inline" }}>
                Jobs Remaining: {remaining_jobs} / {total_jobs} {' '}
              </li>
              {level > 1 && (
                <li style={{ listStyle: "none", display: "inline" }}>
                  | Renew Date: {moment(renew_date).format("MMM DD")}
                </li>
              )}
            </ul>
        </Grid>
      </Grid> 
    </Box>  
  );
};

export default JobHeader;
