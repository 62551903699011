import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { hideDialog } from "../../store/features/dialogs";
import { setJobsEmptyFalse } from "../../store/features/jobs";


const OnboardingDialog = () => {

  const dispatch = useDispatch()
  const { dialog_type } = useSelector((state) => state.dialogs)

    return (
        <Dialog open={dialog_type === 'onboard'} fullWidth maxWidth='sm'>
            <DialogTitle>Welcome to DAISY.</DialogTitle>
            <DialogContent>
              <DialogContentText>
                To design your first job using our CAD tool please click on 'Create a New Job'.
                Alternatively, you can Upload existing floor layouts (revit files) using the 'Upload Existing XMLX Files' button.
              </DialogContentText>
              <DialogActions>
                  <Button
                      variant='contained'
                      color='default'
                      onClick={() => {
                        dispatch(hideDialog())
                        dispatch(setJobsEmptyFalse())
                      }}
                  >
                      Okay
                  </Button>
              </DialogActions>
            </DialogContent>
        </Dialog>
    );
  };

export default OnboardingDialog;