import React, { useEffect } from "react";
import AppRoutes from "./Routes/Routes";
import store from "../store/store";
import { Provider, useSelector } from "react-redux";
import { MuiThemeProvider } from "@material-ui/core";
import { theme } from "../theme";
import CacheBuster from "./CacheBuster";
import { useNavigate } from "react-router-dom";
import { addInterceptor } from "../auth";


const App = () => {
  // useEffect(() => {
  //   window.pendo.initialize({
  //     visitor: {
  //       id: 1,
  //     },
  //     account: {
  //       id: 0,
  //     },
  //   });
  // }, []);

  addInterceptor();


  // return (
  //   <CacheBuster>
  //     {({ loading, isLatestVersion, refreshCacheAndReload }) => {
  //       if (loading) return null;
  //       if (!loading && !isLatestVersion) {
  //         // You can decide how and when you want to force reload
  //         refreshCacheAndReload();
  //       }
        return(
          <>
            <MuiThemeProvider theme={theme}>
              <Provider store={store}>
                <AppRoutes />
              </Provider>
            </MuiThemeProvider>
          </>
        )
  //     }}
  //   </CacheBuster>
  // );
  // );
};
export default App;
