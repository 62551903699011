import { IconButton, Tooltip } from "@material-ui/core";
import React from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { showConfirmSaveDialog } from "../../../store/features/dialogs";
import PlayArrow from "@material-ui/icons/PlayArrow";
import { loadAndGetErrors } from "../../../store/features/cad";

const useStyles = makeStyles((theme) => ({
  tableCell: {
    // padding: theme.spacing(-1)
  },
  icon: {
    "& svg": {
      fontSize: 2,
    },
  },
  button: {
    maxHeight: 25,
  },
}));

const RunAction = ({ job }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <Tooltip title="Design Floor">
      <IconButton
        className={classes.button}
        color="primary"
        variant="outlined"
        disabled={!job.filename}
        size="small"
        onClick={() => {
          // dispatch(showRunDialog([job.id]));
          dispatch(loadAndGetErrors(job.id))
          dispatch(showConfirmSaveDialog(job.id));
        }}
      >
        <PlayArrow fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};

export default RunAction;
