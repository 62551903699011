import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux';

import addMessageListener from './listener';

import limits from './features/limits';
import dialogs from './features/dialogs';
import jobs from './features/jobs';
import dashboard from './features/dashboard';
import user from './features/user';
import loading from './features/loading';
import cad from './features/cad';
import flags from './features/flags'

const reducer = combineReducers({
    limits,
    dialogs,
    jobs,
    dashboard,
    user,
    loading,
    cad,
    flags
  })

const store = configureStore({
  reducer
})

addMessageListener(store.dispatch, store.getState)

export default store;