import React, { useEffect, useState } from "react";
import { ForgotPass } from "../../services/Login";
import Forgot from "./_Forgot";
import SignIn from "./_SignIn";
import SignUp from "./_SignUp";
import CookieConsent from "react-cookie-consent";
import { forgotPassword, login } from "../../auth";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Login = ({
  Error,
  ErrorMessage,
  RouteMode,
  RegStatus,
  RegMessage,
  Token,
  Activate
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { uid } = useSelector(state => state.user);
  const [Mode, setMode] = useState(RouteMode ? RouteMode : "login");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [forgotEmail, setForgotEmail] = useState("");
  const [forgotMessage, setForgotMessage] = useState("");
  const [regUser, setRegUser] = useState("");
  const [regName, setRegName] = useState("");
  const [regPass, setRegPass] = useState("");
  const [regRegion, setRegRegion] = useState("");
  const [regOccupation, setRegOccupation] = useState("");
  const [regNumber, setRegNumber] = useState("");
  const resetForm = () => {
    setUsername("");
    setPassword("");
    setRegUser("");
    setRegName("");
    setRegOccupation("");
    setRegRegion("");
    setRegNumber("");
    setRegPass("");
  };
  const handleCreate = () => {
    // UserReg(regUser, regName, regPass, regOccupation, regRegion, regNumber);
  };
  const handleForgot = () => {
    setMode("forgot");
  };
  const handleSubmitForgot = () => {
    forgotPassword(forgotEmail)
    .then(res => {
      console.log("Password Reset")
    })
    .catch(error => {
      const errorCode = error.code;
      if (errorCode === "auth/user-not-found") {
        ForgotPass(forgotEmail)
        .then(res => {
          setForgotMessage("Please go to the mail address to change your password.")
        })
        .catch(err => {
          if (err.response.status === 400) {
            setForgotMessage("Please enter a valid email.")
          } else {
            setForgotMessage("Email not found.")
          }
        });
      }
    })

  };
  const handleCloseForgot = () => {
    setMode("login");
  };

  useEffect(() => {
    if (uid) {
      navigate("/jobs");
    }
  },[uid])
  const Thanks = ({ Token, Activate }) => {
    const [resThanks, setResThanks] = useState(null);
    useEffect(() => {
      Activate(Token)
        .then(res => {
          // Success path.
          // For all 2xx, 1xx.
          setResThanks(true);
        })
        .catch(res => {
          // For all the fail cases.
          // For all 4xx and 5xx.
          setResThanks(false);
        });
    }, [Activate, Token]);
    return (
      <div className="form thanks">
        <h2>Thanks</h2>
        <h4 className="my-3">
          {resThanks
            ? "Your Account has been created Successfuly"
            : "Your Request has been Denied"}
        </h4>
        <span
          className="btn btn-sm btn-primary py-1 px-2"
          onClick={e => navigate("/login")}
        >
          Back to Login
        </span>
      </div>
    );
  };
  if (uid) {
    return <div></div>;
  }
  return (
    <>
      {Mode === "forgot" && (
        <Forgot
          handleCloseForgot={handleCloseForgot}
          handleSubmitForgot={handleSubmitForgot}
          forgotEmail={forgotEmail}
          setForgotEmail={setForgotEmail}
          forgotMessage={forgotMessage}
          setForgotMessage={setForgotMessage}
        />
      )}
      <div className="Login d-flex justify-content-center align-items-center">
        <div className="Login-Window">
          <div className={"cont" + (Mode === "register" ? " s--signup" : "")}>
            {Token ? (
              <Thanks Token={Token} Activate={Activate} />
            ) : (
              <>
                <SignIn
                  {...{
                    setMode
                  }}
                />
                <SignUp
                  {...{
                    setMode,
                    Mode,
                    RegStatus,
                    RegMessage,
                    resetForm
                  }}
                />
              </>
            )}
          </div>
        </div>
      </div>
      <div className="text-center">
        <CookieConsent
          debug={true}
          buttonStyle={{ color: "white", background:"#155b86",fontSize: "13px" }}
        >
          This website uses cookies to enhance the user experience.{" "}
        </CookieConsent>
      </div>
    </>
  );
};

export default Login;
