import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const SecureRoute = () => {
  const { uid, authLoaded } = useSelector((state) => state.user);
  if (!authLoaded) {
    return null;
  }

  return uid ? <Outlet /> : <Navigate to="/login" />;
};

export default SecureRoute;
