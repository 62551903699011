import React, { Fragment } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  Navigate
} from "react-router-dom";
import Home from "../Home/Home";
import Login from "../Login/Login";
import ResetPassword from "../Login/ResetPassword";
import SecureRoute from "./SecureRoute";
import CreateJobs from "../Jobs/Create";
import Jobs from "../Jobs/Jobs";
import CreateDesign from "../Design/Create";
import Health from "../Health/Health";
import StripePreview from "../Stripe/Preview";
import StripeSuccess from "../Stripe/Success";
import StripeCancel from "../Stripe/Cancel";
import DaisyCAD from "../CAD/DaisyCad";
import View from "../View/View";
import Header from "../Shared/Layout/Header";
import AccessToken from "../API/AccessToken";

const AppRoutes = () => {
  return (
    <Router>
      <Fragment>
        <Header />
        <Routes>
          <Route exact={true} path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Login RouteMode="register" />} />
          {/* <Route path="/users/confirm/:token" element={<Login Token={rp.match.params.token} />} /> */}
          <Route path="/forgot" element={<Login RouteMode="forgot" />} />
          <Route path="/users/password-reset/:token" element={<ResetPassword />} />
          <Route path="/health" element={() => <Health />}/>
          <Route element={<SecureRoute />}>
            <Route path="/jobs" element={<Jobs />} />
            <Route path="/jobs/new" element={<CreateJobs />} />
            <Route path="/jobs/cad/:id" element={<DaisyCAD />} />
            <Route path="/jobs/view/:id" element={<View />} />
          </Route>
          <Route exact path="/designs" element={<SecureRoute />}>
            <Route path="/designs/new" element={<CreateDesign />} />
          </Route>
          <Route exact path="/stripe" element={<SecureRoute />}>
            <Route path="/stripe/preview" element={<StripePreview />} />
            <Route path="/stripe/success" element={<StripeSuccess />} />
            <Route path="/stripe/cancel" element={<StripeCancel />} />
          </Route>
          <Route exact path="/access" element={<SecureRoute />}>
            <Route path="/access/token" element={<AccessToken />} />
          </Route>
        </Routes>
      </Fragment>

    </Router>
  );
};

export default AppRoutes;


{/* 
           */}


{/*  */ }