import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  ListItemSecondaryAction,
  Typography,
  LinearProgress,
  makeStyles,
  Box,
  ListSubheader,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { hideDialog } from "../../store/features/dialogs";
import {
  DownloadArchive,
  DownloadInput,
  DownloadOutput,
} from "../../services/Downloads";
import GetApp from "@material-ui/icons/GetApp";
import { Icon } from "@mdi/react";
import {
  mdiFileCode,
  mdiFileImage,
  mdiFileDelimited,
  mdiFile,
  mdiFileDocument,
} from "@mdi/js";
import { generateIFC, generatePDF, getIFCVersions } from "../../store/features/cad";

const useStyles = makeStyles((theme) => ({
  listIcon: {
    marginRight: theme.spacing(2),
  },
}));

const fileIcons = (file) => {
  let ext = file.split(".")[1];
  switch (ext) {
    case "xmlx":
      return mdiFileCode;
    case "pdf":
      return mdiFileImage;
    case "csv":
      return mdiFileDelimited;
    case "log":
      return mdiFileDocument;
    default:
      return mdiFile;
  }
};


const DownloadDialog = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { dialog_type, value } = useSelector((state) => state.dialogs);
  const { job } = value;
  const { admin } = useSelector((state) => state.user);

  const [filetypes, setFileypes] = useState({
    input: [],
    output: [],
    debug: [],
  });
  const [checked, setChecked] = useState(filetypes);
  const [Loading, setLoading] = useState(false);

  useEffect(() => {
    if (job && job.filetypes) {
      setFileypes(job.filetypes);
      setChecked(job.filetypes);
    }
  }, [job]);

  if (!job || !job.filetypes) return null;

  const handleToggle = (section, value) => () => {
    const currentIndex = checked[section].indexOf(value);
    const newChecked = JSON.parse(JSON.stringify(checked));

    if (currentIndex === -1) {
      newChecked[section].push(value);
    } else {
      newChecked[section].splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const onComplete = () => {
    setLoading(false);
    dispatch(hideDialog());
  };

  const handleSubmit = (value) => {
    let files = [].concat(checked.input, checked.output, checked.debug);
    setLoading(true);
    DownloadArchive([value], files, onComplete);
  };

  return (
    <Dialog open={dialog_type === "download"} fullWidth maxWidth="sm">
      <DialogTitle>
        Job ID #{job ? job.id : ""} | Download Job Files
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <List dense>
            {filetypes.input.length > 0 && (
              <>
                <ListSubheader>Input</ListSubheader>
                {filetypes.input.map((file) => (
                  <ListItem
                    key={1}
                    button
                    onClick={handleToggle("input", file)}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={checked.input.indexOf(file) !== -1}
                        tabIndex={-1}
                        size="small"
                        disableRipple
                      />
                    </ListItemIcon>
                    <Box className={classes.listIcon}>
                      <Icon
                        path={fileIcons(file)}
                        size={0.8}
                        color="#1F5C86"
                      />
                    </Box>
                    <ListItemText primary={file} />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="download"
                        onClick={() => {
                          DownloadInput(job.id);
                        }}
                      >
                        <GetApp fontSize="small" />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </>
            )}
            {filetypes.output.length > 0 && (
              <>
                <ListSubheader>Output</ListSubheader>
                {filetypes.output.map((file) => {
                  if (file === 'design.pdf') {
                    return
                  }
                  return (
                    <>
                      <ListItem
                        key={file}
                        button
                        onClick={handleToggle("output", file)}
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={checked.output.indexOf(file) !== -1}
                            tabIndex={-1}
                            size="small"
                            disableRipple
                          />
                        </ListItemIcon>
                        <Box className={classes.listIcon}>
                          <Icon
                            path={fileIcons(file)}
                            size={0.8}
                            color="#1F5C86"
                          />
                        </Box>
                        <ListItemText primary={`${file}`} />
                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            aria-label="download"
                            onClick={() => {
                              DownloadOutput(job.id, file);
                            }}
                          >
                            <GetApp fontSize="small" />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </>
                  );
                })}
                {filetypes.output.includes("design.pdf") && (
                  <>
                    <ListItem key={"generatepdf"}>
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={false}
                          tabIndex={-1}
                          size="small"
                          disableRipple
                          disabled
                        />
                      </ListItemIcon>
                      <Box className={classes.listIcon}>
                        <Icon
                          path={fileIcons("Generate design.pdf")}
                          size={0.8}
                          color="#1F5C86"
                        />
                      </Box>
                      <ListItemText primary={"output.pdf"} />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="download"
                          onClick={() => {
                            generatePDF(job.id);
                          }}
                        >
                          <GetApp fontSize="small" />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                    {getIFCVersions().map((version) => {
                      return (
                      <ListItem key={"generateIFC"}>
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={false}
                            tabIndex={-1}
                            size="small"
                            disableRipple
                            disabled
                          />
                        </ListItemIcon>
                        <Box className={classes.listIcon}>
                          <Icon
                            path={fileIcons("Generate design.pdf")}
                            size={0.8}
                            color="#1F5C86"
                          />
                        </Box>
                        <ListItemText primary={`output.ifc (${version})`} />
                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            aria-label="download"
                            onClick={() => {
                              generateIFC(job.id, version);
                            }}
                          >
                            <GetApp fontSize="small" />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                      )
                    })}
                  </>
                )}
              </>
            )}
            {filetypes.debug.length > 0 && admin && (
              <>
                <ListSubheader>Debug</ListSubheader>
                {filetypes.debug.map((file) => {
                  return (
                    <>
                      <ListItem
                        key={file}
                        button
                        onClick={handleToggle("debug", file)}
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={checked.debug.indexOf(file) !== -1}
                            tabIndex={-1}
                            size="small"
                            disableRipple
                          />
                        </ListItemIcon>
                        <Box className={classes.listIcon}>
                          <Icon
                            path={fileIcons(file)}
                            size={0.8}
                            color="#1F5C86"
                          />
                        </Box>
                        <ListItemText primary={`${file}`} />
                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            aria-label="download"
                            onClick={() => {
                              DownloadOutput(job.id, file);
                            }}
                          >
                            <GetApp fontSize="small" />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </>
                  );
                })}
              </>
            )}
          </List>
        </DialogContentText>
        {Loading && (
          <>
            <Typography variant="subtitle1">Downloading...</Typography>
            <LinearProgress color="primary" />
          </>
        )}
        <DialogActions>
          <Button
            variant="contained"
            color="default"
            disabled={Loading}
            onClick={() => {
              dispatch(hideDialog());
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="default"
            disabled={Loading}
            onClick={() => {
              handleSubmit(job.id);
            }}
          >
            Download Zip
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default DownloadDialog;
