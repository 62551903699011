// Slice
import { createSlice } from '@reduxjs/toolkit'; 

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    selected: [],
    group: null,
    table: {
      columns: [
        { name: 'id', title: 'ID'},
        { name: 'parent', title: 'Parent ID' },
        { name: 'name', title: 'Reference' },
        { name: 'client', title: 'Client' },
        { name: 'project', title: 'Project' },
        { name: 'house_type', title: 'House Type' },
        { name: 'floor_level', title: 'Floor Level' },
        { name: 'filename', title: 'Input File' },
        { name: 'created_at', title: 'Created At'},
        { name: 'updated', title: 'Updated At'},
        { name: 'status', title: 'Status'},
        { name: 'revisions', title: 'Revisions' },
        { name: 'actions', title: 'Actions'},
      ],
      columnsWidth: [
        { columnName: 'id', width: 100 },
        { columnName: 'parent', width: 100 },
        { columnName: 'name', width: 150 },
        { columnName: 'filename', width: 100 },
        { columnName: 'created_at', width: 125 },
        { columnName: 'updated', width: 125},
        { columnName: 'username', width: 180 },
        { columnName: 'email', width: 180 },
        { columnName: 'status', width: 100 },
        { columnName: 'actions', width: 200 },
        { columnName: 'client', width: 100 },
        { columnName: 'project', width: 100 },
        { columnName: 'house_type', width: 125 },
        { columnName: 'floor_level', width: 125 },
        { columnName: 'revisions', width: 100 },
      ],
      columnsHidden: ['parent'],
      columnsOrder: [
        'id',
        'parent',
        'revisions',
        'name',
        'client',
        'project',
        'house_type',
        'floor_level',
        'filename',
        'created_at',
        'updated',
        'status',
        'username',
        'email',
        'actions'
      ],
      filters: [],
      grouping: []
    }
  },
  reducers: {
    setWidths: (state, action) => {
      state.table.columnsWidth = action.payload;
      localStorage.setItem('table', JSON.stringify(state.table));
    },
    setOrder: (state, action) => {
      state.table.columnsOrder = action.payload;
      localStorage.setItem('table', JSON.stringify(state.table));
    },
    setHidden: (state, action) => {
      state.table.columnsHidden = action.payload;
      localStorage.setItem('table', JSON.stringify(state.table));
    },
    setFilters: (state, action) => {
      state.table.filters = action.payload;
      localStorage.setItem('table', JSON.stringify(state.table));
    },
    setGrouping: (state, action) => {
      state.table.grouping = action.payload;
      localStorage.setItem('table', JSON.stringify(state.table));
    },  
    setSelected: (state, action) => {
      state.selected = action.payload;
    },
    loadTable: (state, action) => {
      if (localStorage.getItem('table')) {
        let table = JSON.parse(localStorage.getItem('table'));
        let columns = JSON.stringify(table.columns);
        if (columns === json_admin_columns || columns === json_user_columns) {
          state.table = table;
        }
      }
    },
    setColumns: (state, action) => {
      state.table.columns = action.payload;
    },
    setGroup: (state, action) => {
      state.group = action.payload;
    }
  },
});

export default dashboardSlice.reducer;

// Actions

const { 
  setWidths,
  setOrder,
  setHidden,
  setFilters,
  setSelected,
  loadTable,
  setColumns,
  setGrouping 
} = dashboardSlice.actions;

export const updateWidths = (sorting) => dispatch => {
  dispatch(setWidths(sorting))
}

export const updateOrder = (order) => dispatch => {
  dispatch(setOrder(order))
}

export const updateHidden = (hidden) => dispatch => {
  dispatch(setHidden(hidden))
}

export const updateFilters = (filters) => dispatch => {
  dispatch(setFilters(filters))
}

export const updateGrouping = (grouping) => dispatch => {
  dispatch(setGrouping(grouping))
}

export const updateSelected = (selected) => dispatch => {
  dispatch(setSelected(selected))
}

export const getTable = () => dispatch => {
  dispatch(loadTable())
}

export const updateColumns = (admin) => dispatch => {
  let columns;
  if (admin) {
    columns = admin_columns
  } else {
    columns = user_columns
  }

  dispatch(setColumns(columns))
}

const admin_columns = [
  { name: 'id', title: 'ID'},
  { name: 'parent', title: 'Parent ID' },
  { name: 'name', title: 'Reference' },
  { name: 'client', title: 'Client' },
  { name: 'project', title: 'Project' },
  { name: 'house_type', title: 'House Type' },
  { name: 'floor_level', title: 'Floor Level' },
  { name: 'filename', title: 'Input File' },
  { name: 'created_at', title: 'Created At'},
  { name: 'updated', title: 'Updated At'},
  { name: 'status', title: 'Status'},
  { name: 'revisions', title: 'Revisions' },
  { name: 'actions', title: 'Actions'},
  { name: 'username', title: 'User' },
  { name: 'email', title: 'Email' },
]

const json_admin_columns = JSON.stringify(admin_columns)

const user_columns = [
  { name: 'id', title: 'ID'},
  { name: 'parent', title: 'Parent ID' },
  { name: 'name', title: 'Reference' },
  { name: 'client', title: 'Client' },
  { name: 'project', title: 'Project' },
  { name: 'house_type', title: 'House Type' },
  { name: 'floor_level', title: 'Floor Level' },
  { name: 'filename', title: 'Input File' },
  { name: 'created_at', title: 'Created At'},
  { name: 'updated', title: 'Updated At'},
  { name: 'status', title: 'Status'},
  { name: 'revisions', title: 'Revisions' },
  { name: 'actions', title: 'Actions'},
]

const json_user_columns = JSON.stringify(user_columns)