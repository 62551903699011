import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BatchDeleteJobs } from '../../services/Jobs';
import { updateSelected } from '../../store/features/dashboard';

import { hideDialog } from '../../store/features/dialogs';
import { getJobs } from '../../store/features/jobs';

function DeleteDialog() {

  const { dialog_type, value } = useSelector((state) => state.dialogs)
  const { admin } = useSelector((state) => state.user)
  const dispatch = useDispatch();

  const [checked, setChecked] = useState(false)

  const label = value.length < 2 
    ? "I acknowledge that the job  will be permanently deleted" 
    : "I acknowledge that selected jobs will be permanently deleted."

  return (
    <Dialog 
      maxWidth='sm'
      fullWidth
      open={dialog_type === 'delete'}
    >
      <DialogTitle>
      {value.length < 2
          ? "Delete Job"
          : `Delete Jobs`}
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          {value.length < 2
          ? "Selected 1 Job"
          : `Selected ${value.length} Jobs`}
          <br/>
          <FormControlLabel 
            control={
              <Checkbox
                id="confirm-delete"
                checked={checked}
                onChange={() => setChecked(!checked)}
                color="primary"
              />
            }
            label={label}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button 
          variant='contained'
          onClick={() => {
            setChecked(false)
            dispatch(hideDialog())
          }}
        >
          Cancel
        </Button>
        <Button 
          id='delete-button'
          variant='contained'
          disabled={!checked}
          onClick={() => { 
            setChecked(false)
            BatchDeleteJobs(value)
            .then(() => {
              dispatch(getJobs(admin))
            })
            dispatch(updateSelected([]))
            dispatch(hideDialog())
          }}
        >
          Delete Job(s)
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteDialog;