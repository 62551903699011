// Slice
import Axios from 'axios'
import { createSlice } from '@reduxjs/toolkit';
import { addLoad } from './loading';
import { removeLoad } from './loading';

export const cadSlice = createSlice({
  name: 'jobs',
  initialState: {
    errors: {
      warnings: [],
      critical: []
    },
    pendingSave: false,
    pendingErrors: false,
    running: false,
    lastSaved: ''
  },
  reducers: {
    setErrors: (state, action) => {
      console.log("Setting Errors")
      let errors = sortErrors(action.payload);
      state.errors = errors;
    },
    setRunning: (state, action) => {
      state.running = action.payload;
    },
    setPendingSave: (state, action) => {
      state.pendingSave = action.payload;
    },
    setLastSave: (state, action) => {
      state.lastSaved = "Last Saved: " + Date().toLocaleLowerCase();
    },
    setPendingErrors: (state, action) => {
      state.pendingErrors = action.payload;
    }
  },
});

export default cadSlice.reducer;

// Actions

const { setPendingErrors, setErrors, setPendingSave, setLastSave } = cadSlice.actions;

export const getErrors = () => dispatch => {
  window.frames[0].postMessage({
    message: 'daisyCad.getErrors'
  }, '*')
}

export const setEmptyErrors = () => dispatch => {
  dispatch(setErrors([]))
}

export const loadAndGetErrors = (id) => dispatch => {
  var cadRef = window.frames[0];
  dispatch(setPendingErrors(true))
  dispatch(addLoad('errorpending'))
  dispatch(setErrors([]))
  Axios.get(`/api/autofloor/token?id=${id}&input=${true}`)
    .then(res => {
      cadRef.postMessage({
        message: 'daisyCad.serverLoad',
        fileId: `${id}`,
        fileName: "output",
        authToken: res.data.token,
        willDownload: false,
        willForward: false,
      }, '*')
    }).catch(err => {
      dispatch(setPendingErrors(false))
      dispatch(removeLoad())
      console.log(err)
    })
}

export const saveJob = (override = false) => dispatch => {
  if (override) {
    dispatch(setLastSave())
    window.frames[0].postMessage({
      message: "daisyCad.serverSave",
      skipConfirmation: true
    }, "*")
    window.frames[0].postMessage({
      message: 'daisyCad.getErrors'
    }, '*')
  } else {
    dispatch(setPendingSave(true))
    window.frames[0].postMessage({
      message: 'daisyCad.getErrors'
    }, '*')
  }
}

export const generatePDF = (id) => {
  var cadRef = window.frames[0];
  Axios.get(`/api/autofloor/token?id=${id}`)
    .then((res) => {
      cadRef.postMessage(
        {
          message: "daisyCad.serverLoadAndPrint",
          fileId: `${id}`,
          fileName: "output",
          authToken: res.data.token,
          willDownload: true,
          willForward: false,
        },
        "*"
      );
    })
    .catch((err) => {
      console.log(err);
    });
};

export const generateIFC = (id, version) => {
  var cadRef = window.frames[0];
  Axios.get(`/api/autofloor/token?id=${id}`)
    .then((res) => {
      cadRef.postMessage(
        {
          message: "daisyCad.serverLoadAndExportIfc",
          ifcVersion: version,
          fileId: `${id}`,
          fileName: "output",
          authToken: res.data.token,
          willDownload: true,
          willForward: false,
        },
        "*"
      );
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getIFCVersions = () => {
  return [
    "IFC2x3",
    "IFC4A2",
    "IFC4X3"
  ]
}




const sortErrors = (errors) => {
  let warnings = [];
  let critical = [];
  errors.forEach(err => {
    if (Object.keys(criticalCodes).includes(err.code)) {
      critical.push(err.string)
    } else if (Object.keys(warningCodes).includes(err.code)) {
      warnings.push(err.string)
    }
  })
  return {
    warnings: warnings.filter((value, index, array) => array.indexOf(value) === index),
    critical: critical.filter((value, index, array) => array.indexOf(value) === index)
  }
}


const warningCodes = {
  'checkProject.disconnectedInternalWallCheck': 'Internal Wall is not connected.',
  'checkProject.floorOpeningWallClash': 'Floor Opening clashes with Wall',
  'checkProject.floorOpeningWallGap': 'Gap between Floor Opening and Wall.',
  'checkProject.nonPerpWallConnection': 'Walls are not quite orthogonal.',
  'checkProject.wallDiscontinuity': 'Discontinuity in External Walls.',
  'checkProject.wallWallClash': 'Wall clashes with wall.'
}


const criticalCodes = {
  'checkProject.floorOpeningNotRectangle': 'Floor Openings must be rectangular.',
  'checkProject.inconsistentExternalSide': 'Wall External Side is inconsistent.',
  'checkProject.inconsistentMemberDepths': 'Mismatched Joist/Beam/Rimboard Depths.',
  'checkProject.insideOutWallLoop': 'Wall Loop is inside out.',
  'checkProject.noBeams': 'No Beam Products are chosen.',
  'checkProject.noJoists': 'No Joist Products are chosen.',
  'checkProject.noRimboardForTimberWall': 'No Rimboard products are selected.',
  'checkProject.spansTooLongForBeams': 'Spans are too long for Beams.',
  'checkProject.spansTooLongForJoists': 'Spans are too long for Joists.',
  'checkProject.unclosedWalls': 'External walls must form closed loops.',
  'checkProject.unsupportedBBO': 'Unsupported Beam By Others.',
}
