import { createTheme } from "@material-ui/core";

export const theme = createTheme({
  typography: {
    fontFamily: 'Roboto'
  },
  palette: {
    primary: {
      main: '#1F5C86'
    },
  }
})