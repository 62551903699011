import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  ListItemSecondaryAction,
  IconButton,
  LinearProgress,
  makeStyles,
  Box,
  ListSubheader,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { hideDialog } from "../../store/features/dialogs";
import {
  DownloadArchive,
  DownloadFiletypeArchive,
} from "../../services/Downloads";
import GetApp from "@material-ui/icons/GetApp";
import { Icon } from "@mdi/react";
import {
  mdiFileCode,
  mdiFileImage,
  mdiFileDelimited,
  mdiFile,
  mdiFileDocument,
} from "@mdi/js";
import { GetFiletypes } from "../../services/Jobs";

const useStyles = makeStyles((theme) => ({
  listIcon: {
    marginRight: theme.spacing(2),
  },
}));

const fileIcons = (file) => {
  let ext = file.split(".")[1];
  switch (ext) {
    case "xmlx":
      return mdiFileCode;
    case "pdf":
      return mdiFileImage;
    case "csv":
      return mdiFileDelimited;
    case "log":
      return mdiFileDocument;
    default:
      return mdiFile;
  }
};

const ArchiveDialog = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { dialog_type, value } = useSelector((state) => state.dialogs);
  const { admin } = useSelector((state) => state.user);

  const [filetypes, setFileypes] = useState({
    input: [],
    output: [],
    debug: [],
  });

  const [checked, setChecked] = useState(filetypes);

  const [Loading, setLoading] = useState(false);

  const handleToggle = (section, value) => () => {
    const currentIndex = checked[section].indexOf(value);
    const newChecked = JSON.parse(JSON.stringify(checked));

    if (currentIndex === -1) {
      newChecked[section].push(value);
    } else {
      newChecked[section].splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const onComplete = () => {
    setLoading(false);
    dispatch(hideDialog());
  };

  const handleSubmit = (value) => {
    let files = [].concat(checked.input, checked.output, checked.debug);
    setLoading(true);
    DownloadArchive(value, files, onComplete);
  };

  const downloadFiletype = (value, file) => {
    setLoading(true);
    DownloadFiletypeArchive(value, file, onComplete);
  };

  useEffect(() => {
    if (dialog_type === "archive") {
      GetFiletypes(value, setFileypes);
    }
  }, [value, dialog_type]);

  useEffect(() => {
    setChecked(filetypes);
  }, [filetypes]);

  return (
    <Dialog open={dialog_type === "archive"} fullWidth maxWidth="sm">
      <DialogTitle>Download Output for {value.length} Jobs</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <List dense>
            {filetypes.input.length > 0 && (
              <>
                <ListSubheader>Input</ListSubheader>
                {filetypes.input.map((file) => (
                  <ListItem
                    key={1}
                    button
                    onClick={handleToggle("input", file)}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={checked.input.indexOf(file) !== -1}
                        tabIndex={-1}
                        size="small"
                        disableRipple
                      />
                    </ListItemIcon>
                    <Box className={classes.listIcon}>
                      <Icon
                        path={fileIcons(file)}
                        title="User Profile"
                        size={0.8}
                        color="#1F5C86"
                      />
                    </Box>
                    <ListItemText primary={file} />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="download"
                        onClick={() => {
                          downloadFiletype(value, file);
                        }}
                      >
                        <GetApp fontSize="small" />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </>
            )}
            {filetypes.output.length > 0 && (
              <>
                <ListSubheader>Output</ListSubheader>
                {filetypes.output.map((file) => {
                  return (
                    <>
                      <ListItem
                        key={file}
                        button
                        onClick={handleToggle("output", file)}
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={checked.output.indexOf(file) !== -1}
                            tabIndex={-1}
                            size="small"
                            disableRipple
                          />
                        </ListItemIcon>
                        <Box className={classes.listIcon}>
                          <Icon
                            path={fileIcons(file)}
                            title="User Profile"
                            size={0.8}
                            color="#1F5C86"
                          />
                        </Box>
                        <ListItemText primary={`${file}`} />
                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            aria-label="download"
                            onClick={() => {
                              downloadFiletype(value, file);
                            }}
                          >
                            <GetApp fontSize="small" />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </>
                  );
                })}
              </>
            )}
            {filetypes.debug.length > 0 && admin && (
              <>
                <ListSubheader>Debug</ListSubheader>
                {filetypes.debug.map((file) => {
                  return (
                    <>
                      <ListItem
                        key={file}
                        button
                        onClick={handleToggle("debug", file)}
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={checked.debug.indexOf(file) !== -1}
                            tabIndex={-1}
                            size="small"
                            disableRipple
                          />
                        </ListItemIcon>
                        <Box className={classes.listIcon}>
                          <Icon
                            path={fileIcons(file)}
                            title="User Profile"
                            size={0.8}
                            color="#1F5C86"
                          />
                        </Box>
                        <ListItemText primary={`${file}`} />
                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            aria-label="download"
                            onClick={() => {
                              downloadFiletype(value, file);
                            }}
                          >
                            <GetApp fontSize="small" />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </>
                  );
                })}
              </>
            )}
          </List>
        </DialogContentText>
        {Loading && (
          <>
            <Typography variant="subtitle1">Downloading...</Typography>
            <LinearProgress color="primary" />
          </>
        )}
        <DialogActions>
          <Button
            variant="contained"
            color="default"
            disabled={Loading}
            onClick={() => {
              dispatch(hideDialog());
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="default"
            disabled={Loading}
            onClick={() => {
              handleSubmit(value);
            }}
          >
            Download Job(s)
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default ArchiveDialog;
