// Slice
import Axios from 'axios'
import { createSlice } from '@reduxjs/toolkit';

export const flagSlice = createSlice({
  name: 'jobs',
  initialState: {
    api_access: false,
  },
  reducers: {
    setFlags: (state, action) => {
      state.api_access = action.payload.api_access;
    },
  },
});

export default flagSlice.reducer;

// Actions

const { setFlags } = flagSlice.actions;

export const setFeatureFlags = () => dispatch => {
    Axios.get('/api/features/')
    .then(res => {
        dispatch(setFlags(res.data))
    })
    .catch(err => {
        console.log(err)
    });
}
