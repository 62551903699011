import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core";
import LaunchIcon from "@material-ui/icons/Launch";
import { makeStyles } from "@material-ui/core/styles";
import { checkout, get_products } from "../../services/Stripe";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
    margin: theme.spacing(2),
    alignContent: "center",
    justifyContent: "center",
  },
  button: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
  card: {
    padding: theme.spacing(1),
  },
}));

const StripePreview = () => {
  const classes = useStyles();
  const [Products, setProducts] = useState(null);

  useEffect(() => {
    get_products(setProducts);
  }, []);

  if (!Products) return null;

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xl={3} xs={9}>
        <Card className={classes.card}>
          <CardHeader title="Tier 1" />
          <CardContent>
            <Typography variant="h5">Optifloor (30 Designs / Month)</Typography>
            <List>
              <ListItem>
                <ListItemText
                  primary="12 Month Subscription"
                  secondary="$599 / Year"
                />
                <ListItemSecondaryAction>
                  <Button
                    variant="contained"
                    endIcon={<LaunchIcon />}
                    onClick={() => {
                      checkout(Products.standard_annually);
                    }}
                  >
                    Subscribe
                  </Button>
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="1 Month Subscription"
                  secondary="$59 / Month"
                />
                <ListItemSecondaryAction>
                  <Button
                    variant="contained"
                    endIcon={<LaunchIcon />}
                    onClick={() => {
                      checkout(Products.standard_monthly);
                    }}
                  >
                    Subscribe
                  </Button>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xl={3} xs={9}>
        <Card className={classes.card}>
          <CardHeader title="Tier 2" />
          <CardContent>
            <Typography variant="h5">
              Optifloor (100 Designs / Month)
            </Typography>
            <List>
              <ListItem>
                <ListItemText
                  primary="12 Month Subscription"
                  secondary="$999 / Year"
                />
                <ListItemSecondaryAction>
                  <Button
                    variant="contained"
                    endIcon={<LaunchIcon />}
                    onClick={() => {
                      checkout(Products.pro_annually);
                    }}
                  >
                    Subscribe
                  </Button>
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="1 Month Subscription"
                  secondary="$99 / Month"
                />
                <ListItemSecondaryAction>
                  <Button
                    variant="contained"
                    endIcon={<LaunchIcon />}
                    onClick={() => {
                      checkout(Products.pro_monthly);
                    }}
                  >
                    Subscribe
                  </Button>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xl={3} xs={9}>
        <Card className={classes.card}>
          <CardHeader title="Enterprise License" />
          <CardContent>
            <Typography variant="h5">
              Please contact us at:  <a href="mailto:contact@daisy.ai">contact@daisy.ai</a>
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default StripePreview;
