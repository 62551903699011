//Slice

import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios';

export const limitSlice = createSlice({
  name: 'limit', 
  initialState: {
    total_jobs: null,
    remaining_jobs: null,
    renew_date: null,
    level: null
  },
  reducers: {    
    setLimits: (state, action) => {
      let remaining_jobs = action.payload.job_limit - action.payload.current_jobs
      state.total_jobs = action.payload.job_limit
      state.remaining_jobs = remaining_jobs
      state.renew_date = action.payload.renew_date
      state.level = action.payload.level
    },
  },
})

export default limitSlice.reducer

// Actions

const { setLimits } = limitSlice.actions

export const fetchLimits = () => async dispatch => {
  await axios.get("/api/jobs/sub/limit/")
  .then(res => {
    dispatch(setLimits(res.data))
  })
  .catch(err => {
    console.log(err)
  })
}