// Slice

import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    authLoaded: false,
    uid: null,
    user_id: null,
    username: null,
    admin: null
  },
  reducers: {
    setUser: (state, action) => {
      state.user_id = action.payload.id
      state.username = action.payload.username
    },
    setAdmin: (state, action) => {
      state.admin = action.payload
    },
    setUserUID: (state, action) => {
      state.uid = action.payload;
      state.authLoaded = true;
    }
  },
});

export default userSlice.reducer;

// Actions

const { setUser, setAdmin, setUserUID } = userSlice.actions;

export const setUID = (uid) => dispatch => {
  dispatch(setUserUID(uid))
}

export const setUserId = (user) => dispatch => {
  dispatch(setUser(user))
}

export const setAdminView = (admin) => dispatch => {
  dispatch(setAdmin(admin))
}