import React from "react";
import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const Loading = () => {
  const classes = useStyles();
  const { general } = useSelector((state) => state.loading);

  return (
    <Backdrop className={classes.backdrop} open={general.length > 0}>
      <CircularProgress style={{color: "#1F5C86"}} />
    </Backdrop>
  );
};

export default Loading;
