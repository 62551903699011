import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { registerPlugin } from "react-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "filepond/dist/filepond.min.css";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import { Card, CardContent, CardHeader, Typography } from "@material-ui/core";
import BlankImage from "../../assets/presets/Blank.png";
import SquareImage from "../../assets/presets/Square.png";
import LImage from "../../assets/presets/L.png";
import RectImage from "../../assets/presets/Rectangle.png";
import TImage from "../../assets/presets/T.png";
import { CreateEmptyJob } from "../../services/Jobs";


registerPlugin(FilePondPluginFileValidateType);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
    marginTop: 32,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    marginTop: theme.spacing(2),
  },
  image: {
    width: 100,
    height: 100,
    margin: theme.spacing(1),
    border: "1px solid #ccc",
    borderRadius: 4,
    cursor: "pointer",
  },
  selectedImage: {
    border: "3px solid #15405D",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(2),
  },
  card: {
    width: "80%",
  },
}));

const CreateJobs = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [designer, setDesigner] = useState("");
  const [client, setClient] = useState("");
  const [project, setProject] = useState("");
  const [houseType, setHouseType] = useState("");
  const [floorLevel, setFloorLevel] = useState("");
  const [selectedImage, setSelectedImage] = useState("blank");
  const [error, setError] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (name.trim() === "") {
      const errors = [];
      if (name.trim() === "") errors.push("Name cannot be empty.");
      setError(errors.join(" "));
    } else {
      CreateEmptyJob(name, designer, client, project, houseType, floorLevel)
        .then((res) => {
          navigate(`/jobs/cad/${res.data.id}?input=true&preset=${selectedImage}`);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardHeader title="Create New Design" />
        <CardContent>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="dense">
                  <TextField
                    required
                    fullWidth
                    label="Job Reference"
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="dense">
                  <TextField
                    fullWidth
                    label="Designer"
                    value={designer}
                    onChange={(event) => setDesigner(event.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="dense">
                  <TextField
                    fullWidth
                    label="Client"
                    value={client}
                    onChange={(event) => setClient(event.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="dense">
                  <TextField
                    fullWidth
                    label="Project"
                    value={project}
                    onChange={(event) => setProject(event.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="dense">
                  <TextField
                    fullWidth
                    label="House Type"
                    value={houseType}
                    onChange={(event) => setHouseType(event.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="dense">
                  <TextField
                    fullWidth
                    label="Floor Level"
                    value={floorLevel}
                    onChange={(event) => setFloorLevel(event.target.value)}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Typography variant="h6" gutterBottom style={{ marginTop: 16 }}>
              Start from preset design:
            </Typography>
            <div className={classes.imageContainer}>
              <Paper
                className={`${classes.image} ${selectedImage === "square" ? classes.selectedImage : ""
                  }`}
                onClick={() => handleImageClick("square")}
              >
                <img src={SquareImage} alt="Image 1" style={{ objectFit: "cover", width: "100%", height: "100%" }} />
                <Typography variant="subtitle2" align="center">Preset 1</Typography>
              </Paper>
              <Paper
                className={`${classes.image} ${selectedImage === "rectangle" ? classes.selectedImage : ""
                  }`}
                onClick={() => handleImageClick("rectangle")}
              >
                <img src={RectImage} alt="Image 2" style={{ objectFit: "cover", width: "100%", height: "100%" }} />
                <Typography variant="subtitle2" align="center">Preset 2</Typography>
              </Paper>
              <Paper
                className={`${classes.image} ${selectedImage === "l" ? classes.selectedImage : ""
                  }`}
                onClick={() => handleImageClick("l")}
              >
                <img src={LImage} alt="Image 3" style={{ objectFit: "cover", width: "100%", height: "100%" }} />
                <Typography variant="subtitle2" align="center">Preset 3</Typography>
              </Paper>
              <Paper
                className={`${classes.image} ${selectedImage === "t" ? classes.selectedImage : ""
                  }`}
                onClick={() => handleImageClick("t")}
              >
                <img src={TImage} alt="Image 4" style={{ objectFit: "cover", width: "100%", height: "100%" }} />
                <Typography variant="subtitle2" align="center">Preset 4</Typography>
              </Paper>
              <Paper
                className={`${classes.image} ${selectedImage === "blank" ? classes.selectedImage : ""
                  }`}
                onClick={() => handleImageClick("blank")}
              >
                <img src={BlankImage} alt="Image 5" style={{ objectFit: "cover", width: "100%", height: "100%" }} />
                <Typography variant="subtitle2" align="center">New Design</Typography>
              </Paper>
            </div>
            <div className={classes.buttonContainer}>
              <Button variant="contained" color="#15405D" type="submit">
                Next
              </Button>
            </div>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default CreateJobs;
