import React, { useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import FormControl from "./FormControl";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReactTooltip from "react-tooltip";

const DesignOptions = ({
  FloorPerformance,
  setFloorPerformance,
  WastePrioritisation,
  setWastePrioritisation,
  ConstructionTimePrioritisation,
  setConstructionTimePrioritisation
}) => {
    const [useFP, setUseFP] = useState(false);

    const design = get_design(
      FloorPerformance, setFloorPerformance,
      useFP, setUseFP,
      WastePrioritisation, setWastePrioritisation,
      ConstructionTimePrioritisation, setConstructionTimePrioritisation,
    )
  
    return (
        <Accordion
          variant='outlined'
        >
            <AccordionSummary 
              expandIcon={<ExpandMoreIcon/>}
            >
                Design Options
            </AccordionSummary>
            <AccordionDetails>
            <Grid container display='flex' spacing={4}>
            {
              design.map((f, key) => (
                <React.Fragment key={key}>
                  {f.checkbox && (
                    <Grid item xs={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={f.checkbox.value}
                            onChange={f.checkbox.onChange}
                          />
                        }
                        label={f.checkbox.label}
                        labelPlacement='end'
                      />
                    </Grid>
                  )}
                    <Grid item xs={12}>
                      <FormControl key={key} {...f.slider} />
                    </Grid>
                </React.Fragment>
              ))
            }
            <ReactTooltip
              type="light"
              place="bottom"
              effect="solid"
              data-background-color="grey"
              arrowColor="#999"
              border={true}
              delayHide={250}
            />
          </Grid>
            </AccordionDetails>
        </Accordion>
    );
  };

  const get_design = (
    FloorPerformance, setFloorPerformance,
    useFP, setUseFP,
    WastePrioritisation, setWastePrioritisation,
    ConstructionTimePrioritisation, setConstructionTimePrioritisation,
  ) => {
    return (
      [
          {
            slider: {
              Type: "Slider",
              Name: "Floor Perfomance (% above code)",
              Label: "Floor performance (% above code)",
              NoLabel: false,
              Row: true,
              disabled: !useFP,
              HelpText:
                "Specifies the target floor performance required as a percentage above the relevant design code. Higher values result in a stiffer floor typically incurring higher costs.",
              Options: {
                marks: {
                0: 0,
                5: 5,
                10: 10,
                15: 15,
                20: 20,
                25: 25,
                },
                min: 0,
                max: 25,
                step: null,
                included: false,
              },
              value: FloorPerformance,
              onChange: (value) => {
                setFloorPerformance(value);
              },
            },
            checkbox: {
              value: useFP,
              onChange: () => {
                  setFloorPerformance(useFP ? -1: 0)
                  setUseFP(!useFP)
              },
              label: 'Override floor performance value specified in file.'
            }
        },
        // {
        //   slider: {
        //     Type: "Slider",
        //     Name: "Waste prioritisation",
        //     Label: "Waste prioritisation",
        //     NoLabel: false,
        //     Row: true,
        //     HelpText:
        //       "Specifies the extent to which site waste reduction should be considered in evolving the design solution. None: disregard waste; Reduce: attempt to reduce waste somewhat; Minimise: heavily penalises designs incurring more waste.",
        //     Options: {
        //       marks: {
        //       0: "none",
        //       1: "reduce",
        //       2: "minimise",
        //       },
        //       min: 0,
        //       max: 2,
        //       step: null,
        //       included: false,
        //     },
        //     value: WastePrioritisation,
        //     onChange: (value) => {
        //       setWastePrioritisation(value);
        //     },
        //   }
        // },
        {
          slider: {
            Type: "Slider",
            Name: "Construction time prioritisation",
            Label: "Construction time prioritisation",
            NoLabel: false,
            disabled: true,
            Row: true,
            HelpText:
                "Specifies the extent to which the time taken to construct the floor on site should be considered in evolving the design solution. None: disregard construction time; Reduce: attempt to reduce construction time somewhat; Minimise: heavily penalises designs incurring more construction time.",
            Options: {
                marks: {
                0: "none",
                1: "reduce",
                2: "minimise",
                },
                min: 0,
                max: 2,
                step: null,
                included: false,
            },
            value: ConstructionTimePrioritisation,
            onChange: (value) => {
              setConstructionTimePrioritisation(value);
            },
          }
        },
      ]
    )
}

export default DesignOptions;