import Axios from "axios";
import qs from "querystring";

export const Authenticate = (username, password) => {
  return Axios.post("/api/login/", qs.stringify({ username, password }), {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });
};

export const CreateUser = (email, name, occupation, region, number, uid ) => {
  return Axios.post(
    "/api/users/create",
    qs.stringify({ email, name, occupation, region, number, uid }),
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    }
  );
};

export const ServerLogout = () => {
  return Axios.post("/api/logout/")
}

export const ForgotPass = email => {
  return Axios.post("/api/users/password-reset", qs.stringify({ email }), {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });
};

export const ResetPassword = (token, password) => {
  return Axios.post("/api/users/password-reset/" + token, qs.stringify({ password }), {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  })
}

export const Activate = token => {
  return Axios.get("/api/users/confirm/" + token);
};

export const setAuthToken = (token, UserLogout) => {
  if (token) {
    Axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    addUnauthorizedInterceptor(UserLogout)
  } else {
    delete Axios.defaults.headers.common["Authorization"];
    ejectUnauthorizedInterceptor()
  }
}

let unauthorizedInterceptor

function ejectUnauthorizedInterceptor() {
  Axios.interceptors.response.eject(unauthorizedInterceptor)
};

function addUnauthorizedInterceptor(UserLogout) {
  unauthorizedInterceptor = Axios.interceptors.response.use(response => {
    return response;
  }, error => {
    if (error.response.status === 401) {
      UserLogout()
    }
  });
}