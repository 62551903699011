import React, { useState } from "react";
import SignInErrors from "./_SignInErrors";
import { login } from "../../auth";

const SignIn = ({ setMode }) => {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [Error, setError] = useState(null);
  
  const handleSubmit = () => {
    setError(null);
    login(email, password)
    .then(res => {
      console.log(res);
    })
    .catch(err => {
      setError(err);
    });
  };

  const handleForgot = () => { 
    setMode("forgot");
  };

  return (
    <div className="form sign-in">
      <h2>Welcome Back</h2>
      <label>
        <span>Email</span>
        <input
          id="email-input"
          type="text"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
      </label>
      <label>
        <span className="password">Password</span>
        <input
          id="password-input"
          type="password"
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
      </label>
      {Error && (
        <div className="text-center">
          <div
            className="alert alert-danger p-2 mt-5 text-center d-inline-block"
            role="alert"
          >
              <SignInErrors error={Error} />
          </div>
        </div>
      )}
      <button type="button" className="submit" onClick={handleSubmit}>
        Sign In
      </button>
      <button type="button" className="forgot-pass" onClick={handleForgot}>
        Forgot password?
      </button>
    </div>
  );
};

export default SignIn;
