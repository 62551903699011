import React from "react";

const SignInErrors = ({ error }) => {
  return error
    ? ( <div className="text-left">
            {error}
        </div>
      )
    : null;
};

export default SignInErrors;
