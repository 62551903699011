import { Button, Checkbox, FormControlLabel, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormControl from "../Shared/Forms/FormControl";
import { hideDialog } from '../../store/features/dialogs';
import { CloneJob } from '../../services/Jobs';
import { getJobs } from '../../store/features/jobs';
import { useNavigate } from 'react-router-dom';

function CloneDialog() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { dialog_type, value } = useSelector((state) => state.dialogs);
  const { job, forceNew } = value;
  const { admin } = useSelector((state) => state.user);


  const [Name, setName] = useState('');
  const [NewJob, setNewJob] = useState(forceNew)
  const [UseCurrentUser, setUseCurrentUser] = useState(true)


  useEffect(() => {
    if (job) {
      setName(job.name + '-clone')
      if (job.is_complete) {
        setNewJob(true);
      } else if (!job.filename) {
        setNewJob(false)
      }
    }
  }, [job])

  useEffect(() => {
    setNewJob(forceNew)
  }, [job, forceNew])

  const handleCloneJob = () => {
    if (NewJob) {
      CloneJob(
        job.id,
        Name,
        UseCurrentUser
      ).then((res) => {
        let id = res.data.id
        dispatch(getJobs(admin))
        navigate(`/jobs/cad/${id}?input=${true}`)
        dispatch(hideDialog())
      })
      .catch(() => {
        dispatch(getJobs(admin))
        dispatch(hideDialog())
      })
    } else {
      navigate(`/jobs/cad/${job.id}?input=${true}`)
    }  
  }

  if (!job) return null;

  return (
    <Dialog 
      maxWidth='sm'
      fullWidth
      open={dialog_type === 'clone'}
    >
      <DialogTitle>
        Edit Input File for {job.name}
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          <FormControlLabel
            control={
              <Checkbox
                id="confirm-delete"
                disabled={forceNew}
                checked={NewJob}
                onChange={() => setNewJob(!NewJob)}
                color="primary"
              />
            }
            label={'Edit as new Job'}
          />
          { NewJob && (
            <>
              <Typography>New Job Name:</Typography>
              {[
                {
                  Type: "text",
                  Name: "name",
                  Label: "Job Reference",
                  NoLabel: true,
                  onChange: (e) => {
                    setName(e.target.value);
                  },
                  value: Name,
                }
              ].map((f, key) => (
                  <FormControl key={key} {...f} />
              ))}
                { admin && (
                  <FormControlLabel 
                    control={
                      <Checkbox
                        id="confirm-delete"
                        checked={UseCurrentUser}
                        onChange={() => setUseCurrentUser(!UseCurrentUser)}
                        color="primary"
                      />
                    }
                    label={'Create as current user.'}
                  />
                )}
            </>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button 
          variant='contained'
          onClick={() => {
            dispatch(hideDialog())
          }}
        >
          Cancel
        </Button>
        <Button 
          id='delete-button'
          variant='contained'
          onClick={() => { 
            handleCloneJob()
            dispatch(hideDialog())
          }}
        >
          Open DaisyCAD
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CloneDialog;