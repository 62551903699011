import React from "react";
import Button from '@material-ui/core/Button';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HelpCenterIcon from '@material-ui/icons/Help';
import AssistantIcon from '@material-ui/icons/Assistant';
import HomeIcon from '@material-ui/icons/Home';
import { AppBar, Toolbar } from "@material-ui/core";
import { useLocation, Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { showSaveDialog } from "../../../store/features/dialogs";
import { logout } from "../../../auth";
import { getAnalytics, logEvent } from "firebase/analytics";



const Header = () => {
  const { uid, authLoaded } = useSelector((state) => state.user);
  const { api_access } = useSelector((state) => state.flags);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const analytics = getAnalytics();
  
  if (!authLoaded || !uid) {
    return null;
  }

  return (
    <AppBar color='transparent' position='sticky' >
      <Toolbar variant='dense'>
       <span 
        style={{ flexGrow: 1 }}
       >
        <img
          src="https://daisy.ai/assets/img/logo.png"
          alt="DAISY GP-Flooring"
          className="nav"
          height={30}
          onClick={() => { navigate('/jobs')}}
        />
        </span>
        <Button
          variant="outlined"
          color="default"
          size="large"
          startIcon={<ExitToAppIcon style={{ color: "#1F5C86" }} />}
          style={{marginRight: '16px'}}
          type="button"
          onClick={e => {
            navigate('/access/token')
          }}
          disabled={!api_access}
        >
          3rd Party Access
        </Button>
        <Button
          variant="outlined"
          color="default"
          size="large"
          startIcon={<AssistantIcon style={{ color: "#1F5C86" }} />}
          style={{marginRight: '16px'}}
          type="button"
          onClick={e => {
            logEvent(analytics, 'open_tutorial')
            let win = window.open('https://daisy.ai/help', "_blank");
            if (win != null) {
              win.focus();
            }
          }}
        >
          Tutorial
        </Button>
        <Button
          variant="outlined"
          color="default"
          size="large"
          startIcon={<HelpCenterIcon style={{ color: "#1F5C86" }} />}
          style={{marginRight: '16px'}}
          type="button"
          onClick={e => {
            let win = window.open('https://daisy.ai/help', "_blank");
            if (win != null) {
              win.focus();
            }
          }}
        >
          Help
        </Button>
        { location.pathname === '/jobs' ? (
          <Button
            variant="outlined"
            color="default"
            size="large"
            startIcon={<ExitToAppIcon style={{ color: "#1F5C86" }} />}
            type="button"
            onClick={e => {
              e.preventDefault();
              logout();
            }}
          >
            Sign Out
          </Button>
        ) : (
          <Button
            variant="outlined"
            color="default"
            size="large"
            startIcon={<HomeIcon style={{ color: "#1F5C86" }} />}
            type="button"
            to='/jobs'
            component={Link}
            onClick={e => {
              if (location.pathname.includes('/jobs/cad/')) {
                e.preventDefault();
                dispatch(showSaveDialog())
              }
            }}
          >
            Dashboard
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
