import { IconButton, Tooltip } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core";
import PlayArrow from "@material-ui/icons/PlayArrow";
import { RunRedesign } from "../../../services/Revisions";

const useStyles = makeStyles((theme) => ({
  tableCell: {
    // padding: theme.spacing(-1)
  },
  icon: {
    "& svg": {
      fontSize: 2,
    },
  },
  button: {
    maxHeight: 25,
  },
}));

const RedesignAction = ({ job }) => {
  const classes = useStyles();

  return (
    <Tooltip title="Run Redesign">
      <IconButton
        className={classes.button}
        color="primary"
        variant="outlined"
        size="small"
        onClick={() => {
          RunRedesign(job.id);
        }}
      >
        <PlayArrow fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};

export default RedesignAction;
