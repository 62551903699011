import React from "react";
import { makeStyles, Typography, Grid, Card, CardHeader, CardContent } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import InputActions from "./InputActions";
import OutputActions from "./OutputActions";


const useStyles = makeStyles((theme) => ({
  body: {
    margin: theme.spacing(2),
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  tableCell: {
    backgroundColor: '#CCCCC',
  },
  info: {
    marginRight: theme.spacing(2)
  },
  griditem: {
    marginBottom: theme.spacing(2),
  }
}));

const DateFormatter = (value) => {
  return moment
    .utc(value, 'YYYY-MM-DDThh:mm:ss')
    .local()
    .format('YYYY-MM-DD HH:mm:ss');
}

const JobInfo = (props) => {
  const classes = useStyles();
  const { Job } = props;

  if (Job == null) {
    return null;
  }

  return (
    <>
      <Card className={classes.body}>
        <CardHeader
          title={`Reference: ${Job.name}  #${Job.id}`}
          subheader={`Username: ${Job.username}`}
        />
        <CardContent>
          <Grid container>
            <Grid className={classes.griditem} item xs={12}>
              <Typography variant='h6'>Status: {Job.status}</Typography>
            </Grid>
            <Grid className={classes.griditem} item md={6} xs={12}>
              <InputActions Job={Job} />  
            </Grid>
            <Grid className={classes.griditem} item md={6} xs={12}>
              <OutputActions Job={Job} />
            </Grid>
            <Grid className={classes.griditem} item xs={12}>
              <Typography variant='h6'>Information</Typography>
            </Grid>
            <Grid className={classes.griditem} item xs={12}>
              <TextField
                disabled 
                className={classes.info}
                label='Client'
                value={Job.client}
              />
              <TextField
                disabled 
                className={classes.info}
                label='Project'
                value={Job.project}
              />

              <TextField
                disabled 
                className={classes.info}
                label='House Type'
                value={Job.house_type}
              />
              <TextField
                disabled 
                className={classes.info}
                label='Floor Level'
                value={Job.floor_level}
              />
            </Grid>
            <Grid className={classes.griditem} item xs={12}>
              <TextField
                disabled 
                className={classes.info}
                label='Created'
                value={`${DateFormatter(Job.created_at)}`}
              />
              <TextField
                disabled 
                className={classes.info}
                label='Last Updated'
                value={`${DateFormatter(Job.updated)}`}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>      
    </>
  );
};

export default JobInfo;
