import Axios from "axios";

const OpenFile = (headers, data) => {
    const objectURL = window.URL.createObjectURL(data);
    let filename = headers['content-disposition'].split('filename="')[1].slice(0, -1)
    let templink = document.createElement('a');
    templink.href = objectURL;
    templink.download = filename
    templink.click();
    window.URL.revokeObjectURL(objectURL)
    templink.remove()
}

export const DownloadInput = (job_id) => {
    Axios.get(
      "/api/download/input/" + job_id,
      { responseType: 'blob' }
    )
    .then(res => {
      OpenFile(res.headers, res.data)
    })
    .catch(err => {
      console.log(err)
    })
  }
export const DownloadOutput = (job_id, filetype) => {
    Axios.get(
      "/api/download/output/" + job_id + "?filetype=" + filetype,
      { responseType: 'blob' }
    )
    .then(res => {
        OpenFile(res.headers, res.data)
    })
    .catch(err => {
      console.log(err)
    })
  }
export const DownloadZip = (job_id) => {
    Axios.get(
      "/api/download/output/zip/" + job_id,
      {responseType: 'blob'}
    )
    .then(res => {
        OpenFile(res.headers, res.data)
    })
    .catch(err => {
      console.log(err)
    })
  }
export const DownloadArchive = (job_ids, types, onComplete) => {
    let job_ids_querystring = '?'
    job_ids.forEach(id => {
      job_ids_querystring += 'job_ids=' + id + '&'
    })

    let types_querystring = '&'
    types.forEach(type => {
      types_querystring += 'filetypes=' + type + '&'
    })
  
    Axios.get(
      "/api/download/batch/archive/" + 
      job_ids_querystring.slice(0,-1) + types_querystring.slice(0, -1),
      { responseType: 'blob' }
    )
    .then(res => {
      OpenFile(res.headers, res.data)
      onComplete()
    })
    .catch(err => {
      console.log(err)
      onComplete()
    })
  }

export const DownloadFiletypeArchive = (job_ids, type, onComplete) => {
  let job_ids_querystring = '?'
  job_ids.forEach(id => {
    job_ids_querystring += 'job_ids=' + id + '&'
  })

  Axios.get(
    `/api/download/batch/archive/${type}` + 
    job_ids_querystring.slice(0,-1),
    { responseType: 'blob' }
  )
  .then(res => {
    OpenFile(res.headers, res.data)
    onComplete()
  })
  .catch(err => {
    console.log(err)
    onComplete()
  })
}