import { Table } from "@devexpress/dx-react-grid-material-ui";
import { Tooltip } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import SuccessActions from "./ActionCells/SuccessActions";
import WIPActions from "./ActionCells/WIPActions";
import FailedActions from "./ActionCells/FailedActions";
import RunningActions from "./ActionCells/RunningActions";

const useStyles = makeStyles((theme) => ({
  tableCell: {
    backgroundColor: "#CCCCCC",
  },
}));


const StatusCell = ({ row, ...restProps }) => {
  const classes = useStyles();
  return (
    <Table.Cell
      {...restProps}
      id={row.name + "-" + row.status}
      className={`${row.is_revision ? classes.tableCell : ""} ${
        restProps.className
      }`}
    >
      {row.status}
      {row.status === "FAILED" && row.error ? (
        <Tooltip title={row.error} placement="right">
          <ErrorOutlineIcon fontSize="small" />
        </Tooltip>
      ) : null}
    </Table.Cell>
  );
};

const TableCell = (props) => {
  const classes = useStyles();
  const { column, row } = props;
  if (column.name === "actions") {
    if (row.status === "SUCCEEDED") {
      return <SuccessActions {...props} />
    } else if (row.status === "WIP") {
      return <WIPActions {...props} />
    } else if (row.status === "FAILED") {
      return <FailedActions {...props} />
    } else {
      return <RunningActions {...props} />
    }
  } else if (column.name === "status") {
    return <StatusCell {...props} />;
  }
  return (
    <Table.Cell
      {...props}
      className={`${row.is_revision ? classes.tableCell : ""} ${
        props.className
      }`}
    />
  );
};

export default TableCell;
