import React from "react";
import { Card, CardContent, CardHeader, Grid, Button } from '@material-ui/core'
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
    margin: theme.spacing(2),
    alignContent: "center",
    justifyContent: "center",
  },
  button: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  card: {
    padding: theme.spacing(1)
  }
}));

const StripeCancel = () => {
  const classes = useStyles()

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={6}>
        <Card className={classes.card}>
          <CardHeader
            title='Payment Incomplete'
          />
          <CardContent>
            <Button
              className={classes.button}
              variant='contained'
              component={Link}
              to='/jobs'
            >
              Return To Dashboard
            </Button>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default StripeCancel;
