import React from "react";
import Slider from "rc-slider";
import TextareaAutosize from "react-textarea-autosize";
import "rc-slider/assets/index.css";

const FormControl = ({
  Type,
  Name,
  HelpText,
  Label,
  NoLabel,
  Options,
  Row,
  ...props
}) => {
  switch (Type) {
    case "text":
    case "email":
    case "number":
      return (
        <div className={"form-group" + (Row ? " row" : "")}>
          {!NoLabel && (
            <label
              htmlFor={Name}
              className={Row ? "col-sm-4 col-form-label" : null}
            >
              {Label}
            </label>
          )}
          {Row ? (
            <>
              <div className="col-sm-8">
                <input
                  type={Type}
                  className="form-control"
                  id={Name}
                  aria-describedby={Name + "Help"}
                  placeholder={"Enter " + Label}
                  {...props}
                />
              </div>
            </>
          ) : (
            <>
              <input
                type={Type}
                className="form-control"
                id={Name}
                aria-describedby={Name + "Help"}
                placeholder={"Enter " + Label}
                {...props}
              />
              {HelpText && (
                <small id={Name + "Help"} className="form-text text-muted">
                  {HelpText}
                </small>
              )}
            </>
          )}
        </div>
      );
    case "select":
      return (
        <div className={"form-group" + (Row ? " row" : "")}>
          {!NoLabel && (
            <label
              htmlFor={Name}
              className={Row ? "col-sm-4 col-form-label" : null}
            >
              {Label}
            </label>
          )}
          {Row ? (
            <>
              <div className="col-sm-8">
                <select
                  className="form-control"
                  id={Name}
                  aria-describedby={Name + "Help"}
                  {...props}
                >
                  {Options &&
                    Options.map((o, key) => (
                      <option value={o} key={key}>
                        {o}
                      </option>
                    ))}
                </select>
              </div>
            </>
          ) : (
            <>
              <select
                className="form-control"
                id={Name}
                aria-describedby={Name + "Help"}
                {...props}
              >
                {Options &&
                  Options.map((o, key) => (
                    <option value={o} key={key}>
                      {o}
                    </option>
                  ))}
              </select>
              {HelpText && (
                <small id={Name + "Help"} className="form-text text-muted">
                  {HelpText}
                </small>
              )}
            </>
          )}
        </div>
      );
    case "Slider":
      const { marks, min, max, step, included } = Options;
      const curSlider = (
        <Slider
          min={min}
          max={max}
          marks={marks}
          step={step}
          included={included}
          {...props}
        />
      );
      return (
        <div className={"form-group" + (Row ? " row" : "")}>
          {!NoLabel && (
            <label
              htmlFor={Name}
              className={Row ? "col-sm-4 col-form-label text-left" : null}
              data-tip={HelpText}
            >
              {Label}
            </label>
          )}
          {Row ? (
            <>
              <div className="col-sm-6">
                {curSlider}
                {HelpText && (
                  <p className="mt-4 text-left">
                    <small
                      id={Name + "Help"}
                      className="form-text text-muted"
                    ></small>
                  </p>
                )}
              </div>
            </>
          ) : (
            <>
              {curSlider}
              {HelpText && (
                <small id={Name + "Help"} className="form-text text-muted">
                  {HelpText}
                </small>
              )}
            </>
          )}
        </div>
      );
    case "AutoResizeTextarea":
      const textArea = <TextareaAutosize {...props} className="form-control" />;
      return (
        <div className={"form-group" + (Row ? " row" : "")}>
          {!NoLabel && (
            <label
              htmlFor={Name}
              className={Row ? "col-sm-4 col-form-label" : null}
            >
              {Label}
            </label>
          )}
          {Row ? (
            <>
              <div className="col-sm-8">{textArea}</div>
            </>
          ) : (
            <>
              {textArea}
              {HelpText && (
                <small id={Name + "Help"} className="form-text text-muted">
                  {HelpText}
                </small>
              )}
            </>
          )}
        </div>
      );
    default:
      break;
  }
};

export default FormControl;
