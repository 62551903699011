import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box } from '@material-ui/core';
import { GetJob } from "../../services/Jobs";
import DialogController from "../Dialogs/DialogController";
import { RevisionTable } from "./RevisionTable";
import JobInfo from "./JobInfo";


const View = () => {
  const [Job, setJob] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    GetJob(id, setJob)
  }, [id]);

  return (
    <>
    <DialogController />
    {Job && (
      <Box>
        <JobInfo Job={Job} />
        <RevisionTable Job={Job} />
      </Box>
    )}
    </>
  );
};

export default View;
