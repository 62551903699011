import React from "react";
import { useDispatch } from "react-redux";
import { makeStyles, Typography, Button } from "@material-ui/core";
import { showCloneDialog } from "../../store/features/dialogs";
import { DownloadInput } from "../../services/Downloads";


const useStyles = makeStyles((theme) => ({
  body: {
    flex: true,
    margin: theme.spacing(2),
    marginTop: theme.spacing(0),
  },
  tableCell: {
    backgroundColor: '#CCCCC',
  },
  info: {
    marginRight: theme.spacing(2)
  },
  button: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  griditem: {
    marginBottom: theme.spacing(2),
  }
}));

const InputActions = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { Job } = props;

  if (Job == null) {
    return null;
  }

  return (
    <>
      <Typography 
        className={classes.griditem} 
        variant='subtitle1'
      >
        Input Actions
      </Typography>
      <Button 
        className={classes.button} 
        variant='contained' 
        color='primary'
        onClick={() => {
          dispatch(showCloneDialog(Job))
        }}
      >
        Edit Input File
      </Button>
      <Button 
        className={classes.button} 
        variant='contained' 
        color='primary'
        onClick={() => {
          DownloadInput(Job.id)
        }}
      >
        Download Input
      </Button>
    </>
  );
};

export default InputActions;
