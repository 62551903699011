import { Table } from "@devexpress/dx-react-grid-material-ui";
import { Box } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core";
import ViewAction from "../../Shared/Actions/ViewAction";
import DownloadAction from "../../Shared/Actions/DownloadAction";
import NewRevisionAction from "../../Shared/Actions/NewRevisionAction";
import DeleteAction from "../../Shared/Actions/DeleteAction";

const useStyles = makeStyles((theme) => ({
  tableCell: {
    // padding: theme.spacing(-1)
  },
  icon: {
    "& svg": {
      fontSize: 2,
    },
  },
  button: {
    maxHeight: 25,
  },
}));

const SuccessActions = ({ row, ...restProps }) => {
  const classes = useStyles();

  console.log(row);

  return (
    <Table.Cell
      {...restProps}
      className={`${restProps.className} ${classes.tableCell}`}
    >
      <Box display="flex">
        <span style={{ flexGrow: 1 }} />
        <ViewAction job={row} />
        <NewRevisionAction job={row} />
        <DownloadAction job={row} />
        <DeleteAction job={row} />
      </Box>
    </Table.Cell>
  );
};

export default SuccessActions;
