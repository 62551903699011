import { IconButton, Tooltip } from "@material-ui/core";
import React from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { showConfirmSaveDialog, showWorkerSelectionDialog } from "../../../store/features/dialogs";
import PlayArrow from "@material-ui/icons/PlayArrow";
import GridIcon from "@material-ui/icons/ViewQuiltOutlined";
import { loadAndGetErrors } from "../../../store/features/cad";

const useStyles = makeStyles((theme) => ({
  tableCell: {
    // padding: theme.spacing(-1)
  },
  icon: {
    "& svg": {
      fontSize: 2,
    },
  },
  button: {
    maxHeight: 25,
  },
}));

const SelectWorkerAction = ({ job }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <Tooltip title="Select Design">
      <IconButton
        className={classes.button}
        color="primary"
        variant="outlined"
        size="small"
        onClick={() => {
          dispatch(showWorkerSelectionDialog(job.id))
        }}
      >
        <GridIcon fontSize="medium" />
      </IconButton>
    </Tooltip>
  );
};

export default SelectWorkerAction;
