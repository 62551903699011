import { IconButton, Tooltip } from "@material-ui/core";
import React from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { showDeleteDialog } from "../../../store/features/dialogs";

const useStyles = makeStyles((theme) => ({
  tableCell: {
    // padding: theme.spacing(-1)
  },
  icon: {
    "& svg": {
      fontSize: 2,
    },
  },
  button: {
    maxHeight: 25,
  },
}));

const DeleteAction = ({ job }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <Tooltip title="Delete Job">
      <IconButton
        className={classes.button}
        color="primary"
        variant="outlined"
        size="small"
        onClick={() => {
          dispatch(showDeleteDialog([job.id]));
        }}
      >
        <DeleteIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};

export default DeleteAction;
