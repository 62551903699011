import { Plugin } from '@devexpress/dx-react-core';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { DragDropProvider } from '@devexpress/dx-react-grid-material-ui';
import moment from 'moment';
import React from 'react';

const DateFormatter = ({ value }) => {
  return moment
    .utc(value, 'YYYY-MM-DDThh:mm:ss')
    .local()
    .format('YYYY-MM-DD HH:mm:ss');
}

const FilePathFormatter = ({ value }) => {
  if (value === 'Revision') {
    return 'N/A'
  } else {
    return value ? 
      value.split("/").reverse()[0] : "No File"
  }
}

const TableProviders = () => {
  return (
    <Plugin>
      <DataTypeProvider
        for={['name', 'parent', 'status', 'filename', 'actions', 'client', 'project', 'house_type', 'floor_level', 'username']}
        availableFilterOperations={[]}
      />
      <DragDropProvider />
      <DataTypeProvider
        for={['id']}
        availableFilterOperations={['contains', 'greaterThanOrEqual', 'lessThanOrEqual']}
      />
      <DataTypeProvider
        for={['filename']}
        formatterComponent={FilePathFormatter}
        availableFilterOperations={[]}
      />
      <DataTypeProvider
        for={['created_at', 'updated']}
        formatterComponent={DateFormatter}
        availableFilterOperations={['contains', 'greaterThan', 'lessThan']}
      />
    </Plugin>
  )
}

export default TableProviders;