import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Paper,
  Button,
  Grid,
  List,
  makeStyles,
  ListItem,
  ListItemText,
  Dialog,
  DialogTitle,
  Container,
  DialogActions,
  ListItemAvatar,
  Avatar,
  DialogContent,
  DialogContentText,
  Drawer,
} from "@material-ui/core";
import { theme } from "../../theme";
import { hideDialog } from "../../store/features/dialogs";
import Axios from "axios";
import { addLoad, removeLoad } from "../../store/features/loading";
import Loading from "../Shared/Layout/Loading";

const useStyles = makeStyles((theme) => ({
  sidebar: {
    width: "400px",
  },
  content: {
    marginLeft: "400px",
    flexGrow: 1,
  }
}));

const WorkerSelectionDialog = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { dialog_type, value } = useSelector((state) => state.dialogs);
  const { job, data } = value;
  const [Selected, setSelected] = React.useState(0);

  const handleListItemClick = (event, index) => {
    setSelected(index);
  };

  const selectWorker = () => {
    dispatch(addLoad("selecting worker"));
    dispatch(hideDialog());
    Axios.post(`/api/worker/select/${job}?worker_index=${Selected}`)
      .then((res) => {
        dispatch(removeLoad());
      })
      .catch((err) => {
        console.log(err);
        dispatch(removeLoad());
      });
  };

  if (!data) return null;

  if (data.length === 0)
    return (
      <Dialog
        open={dialog_type === "workerSelect"}
        fullWidth
        maxWidth="sm"
        style={{ minHeight: "100%" }}
      >
        <DialogTitle>No Design Selection</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Design Selection is not available for this job.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              dispatch(hideDialog());
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );

  return (
    <Dialog
      open={dialog_type === "workerSelect"}
      fullWidth
      maxWidth="xl"
      style={{ minHeight: "400px" }}
    >
      <DialogTitle>Select Design</DialogTitle>
      <DialogContent>
        <Loading />
        <div className={classes.sidebar}>
          <Drawer
            variant="permanent"
            PaperProps={{
              style: {
                position: "absolute",
                marginTop: "64px",
                height: "80vh",
                width: "400px",
              },
            }}
          >
            <List className={classes.root}>
              {data.map((worker, index) => (
                <ListItem
                  selected={Selected === index}
                  onClick={(event) => handleListItemClick(event, index)}
                >
                  <ListItemText
                    primary={worker.name}
                    secondary={worker.primary ? "Primary Worker" : ""}
                  />
                  <ListItemAvatar>
                    <Avatar
                      variant="square"
                      style={{
                        width: theme.spacing(16),
                        height: theme.spacing(16),
                      }}
                      src={worker.image}
                      sizes="large"
                    />
                  </ListItemAvatar>
                </ListItem>
              ))}
            </List>
          </Drawer>
        </div>
        <div className={classes.content} fullWidth>
          <Container>
          <img
          fullWidth
            className={classes.root}
            src={data[Selected].image}
            alt="Selected Worker Image"
            style={{ maxHeight: "80vh", alignSelf: "center", justifySelf: "center" }}

          />
          </Container>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => {
            dispatch(hideDialog());
          }}
        >
          Close Selection
        </Button>
        {data[Selected].primary ? (
          <Button disabled color="primary" variant="outlined">
            Worker is Primary
          </Button>
        ) : (
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              selectWorker();
            }}
          >
            Select Worker as Primary
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default WorkerSelectionDialog;
