import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FilePond, registerPlugin } from "react-filepond";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import BackspaceIcon from "@material-ui/icons/Backspace";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "filepond/dist/filepond.min.css";
import FormControl from "../Shared/Forms/FormControl";
import { SaveJob, CreateJob, GetUploadLimit } from "../../services/Jobs";
import { GetGroup } from "../../services/Admin";
import moment from "moment";
import DesignOptions from "../Shared/Forms/_DesignOptions";
import OptimisationOptions from "../Shared/Forms/_OptimisationOptions";
import { useSelector, useDispatch } from "react-redux";
import { fetchLimits } from "../../store/features/limits";
import { getAnalytics, logEvent } from "firebase/analytics"

registerPlugin(FilePondPluginFileValidateType);

const CreateJobs = () => {
  const { total_jobs, remaining_jobs, renew_date, level } = useSelector(
    (state) => state.limits
  );
  const { admin } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const analytics = getAnalytics();

  const [Name, setName] = useState("");
  const [FloorPerformance, setFloorPerformance] = useState(-1);
  const [WastePrioritisation, setWastePrioritisation] = useState(0);
  const [ConstructionTimePrioritisation, setConstructionTimePrioritisation] =
    useState(1);
  const [NumWorkers, setNumWorkers] = useState(4);
  const [NumAgents, setNumAgents] = useState(100);
  const [NumGenerations, setNumGenerations] = useState(20);
  const [ConfigJson, setConfigJson] = useState("{}");
  const [Files, setFiles] = useState([]);
  const [Error, setError] = useState(null);
  const [UploadLimit, setUploadLimit] = useState(20);
  const [, setUsers] = useState([]);
  const [, setGroup] = useState([]);
  useEffect(() => {
    GetUploadLimit()
      .then((res) => {
        setUploadLimit(res.data.batch_upload_limit);
      })
      .catch((err) => {
        console.log(err);
      });
  });

  useEffect(() => {
    dispatch(fetchLimits());
    GetGroup(setUsers, setGroup);
  }, [dispatch]);

  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-12">
          <Button
            to="/jobs"
            variant="contained"
            color="default"
            className="float-right"
            startIcon={<BackspaceIcon style={{ color: "#1F5C86" }} />}
            component={Link}
          >
            Back
          </Button>
          <h2>Upload Files</h2>
          <h6>
            Please upload Revit file downloaded using{" "}
            <a href="https://daisy.ai/downloads">DAISY add-on</a>
          </h6>
          <ul
            style={{
              listStyle: "none",
              float: "right",
              width: 500,
              padding: 6,
              textAlign: "right",
              flexGrow: 1,
              color: "grey",
              fontSize: 13,
            }}
          >
            <li style={{ listStyle: "none" }}>Total Jobs: {total_jobs}</li>
            <li style={{ listStyle: "none" }}>
              {" "}
              Remaining Jobs: {remaining_jobs}
            </li>
            {level > 0 && (
              <li style={{ listStyle: "none" }}>
                Renew Date: {moment(renew_date).format("MMM DD")}
              </li>
            )}
          </ul>
          <form
            className="create-form"
            onSubmit={(e) => {
              e.preventDefault();
              logEvent(analytics, 'upload_and_design_job')
              if (Name.trim() === "" || Files.length === 0) {
                const errors = [];
                if (Name.trim() === "") errors.push("Name cannot be empty.");
                if (!Files) errors.push("Please upload an XML file.");
                setError(errors.join(" "));
              } else {
                var files = Files.map((file) => {
                  return file.file;
                });
                CreateJob(
                  Name,
                  files,
                  FloorPerformance,
                  WastePrioritisation,
                  ConstructionTimePrioritisation,
                  NumAgents,
                  NumGenerations,
                )
                  .then((res) => {
                    if (res !== undefined) {
                      navigate("/jobs");
                    } else {
                      setError("Error creating job");
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
            }}
          >
            {Error && (
              <div className="alert alert-danger">
                <p className="m-0">{Error}</p>
              </div>
            )}
            {[
              {
                Type: "text",
                Name: "name",
                Label: "Job Name (Required)",
                NoLabel: true,
                onChange: (e) => {
                  setName(e.target.value);
                },
                value: Name,
              },
            ].map((f, key) => (
              <FormControl key={key} {...f} />
            ))}
            <FilePond
              files={Files}
              onupdatefiles={setFiles}
              allowMultiple={true}
              maxFiles={UploadLimit}
            />
            <DesignOptions
              FloorPerformance={FloorPerformance}
              setFloorPerformance={setFloorPerformance}
              WastePrioritisation={WastePrioritisation}
              setWastePrioritisation={setWastePrioritisation}
              ConstructionTimePrioritisation={ConstructionTimePrioritisation}
              setConstructionTimePrioritisation={
                setConstructionTimePrioritisation
              }
            />
            {true && (
              <OptimisationOptions
                NumAgents={NumAgents}
                setNumAgents={setNumAgents}
                NumGenerations={NumGenerations}
                setNumGenerations={setNumGenerations}
              />
            )}
            <label>
              <Button
                variant="contained"
                className="mr-3 mt-3"
                type="submit"
                size="large"
                startIcon={<SaveIcon style={{ color: "#1F5C86" }} />}
                color="default"
                onClick={(e) => {
                  e.preventDefault();
                  logEvent(analytics, 'upload_and_save_job')
                  var files = Files.map((file) => {
                    return file.file;
                  });
                  SaveJob(Name, files)
                    .then((res) => {
                      if (res !== undefined) {
                        navigate("/jobs");
                      } else {
                        setError("Error creating job");
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }}
              >
                Save Jobs
              </Button>
            </label>
            <label htmlFor="submit-create-job">
              <Button
                variant="contained"
                id="submit-create-job"
                className="mt-3"
                type="submit"
                size="large"
                startIcon={<PlayArrowIcon style={{ color: "#1F5C86" }} />}
                color="default"
              >
                Design Floor
              </Button>
            </label>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateJobs;
