import React from 'react';
import { Select, MenuItem } from '@material-ui/core';


export const Country = ({
   Region,
   setRegion
}
) => {
   return (
      <Select id="country" name="country" value={Region} onChange={e => setRegion(e.target.value)}>
         <MenuItem key={"Afganistan"} value="Afganistan">Afghanistan</MenuItem>
         <MenuItem key={"Albania"} value="Albania">Albania</MenuItem>
         <MenuItem key={"Algeria"} value="Algeria">Algeria</MenuItem>
         <MenuItem key={"American"} value="American Samoa">American Samoa</MenuItem>
         <MenuItem key={"Andorra"} value="Andorra">Andorra</MenuItem>
         <MenuItem key={"Angola"} value="Angola">Angola</MenuItem>
         <MenuItem key={"Anguilla"} value="Anguilla">Anguilla</MenuItem>
         <MenuItem key={"Antigua"} value="Antigua & Barbuda">Antigua & Barbuda</MenuItem>
         <MenuItem key={"Argentina"} value="Argentina">Argentina</MenuItem>
         <MenuItem key={"Armenia"} value="Armenia">Armenia</MenuItem>
         <MenuItem key={"Aruba"} value="Aruba">Aruba</MenuItem>
         <MenuItem key={"Australia"} value="Australia">Australia</MenuItem>
         <MenuItem key={"Austria"} value="Austria">Austria</MenuItem>
         <MenuItem key={"Azerbaijan"} value="Azerbaijan">Azerbaijan</MenuItem>
         <MenuItem key={"Bahamas"} value="Bahamas">Bahamas</MenuItem>
         <MenuItem key={"Bahrain"} value="Bahrain">Bahrain</MenuItem>
         <MenuItem key={"Bangladesh"} value="Bangladesh">Bangladesh</MenuItem>
         <MenuItem key={"Barbados"} value="Barbados">Barbados</MenuItem>
         <MenuItem key={"Belarus"} value="Belarus">Belarus</MenuItem>
         <MenuItem key={"Belgium"} value="Belgium">Belgium</MenuItem>
         <MenuItem key={"Belize"} value="Belize">Belize</MenuItem>
         <MenuItem key={"Benin"} value="Benin">Benin</MenuItem>
         <MenuItem key={"Bermuda"} value="Bermuda">Bermuda</MenuItem>
         <MenuItem key={"Bhutan"} value="Bhutan">Bhutan</MenuItem>
         <MenuItem key={"Bolivia"} value="Bolivia">Bolivia</MenuItem>
         <MenuItem key={"Bonaire"} value="Bonaire">Bonaire</MenuItem>
         <MenuItem key={"Bosnia"} value="Bosnia & Herzegovina">Bosnia & Herzegovina</MenuItem>
         <MenuItem key={"Botswana"} value="Botswana">Botswana</MenuItem>
         <MenuItem key={"Brazil"} value="Brazil">Brazil</MenuItem>
         <MenuItem key={"British"} value="British Indian Ocean Ter">British Indian Ocean Ter</MenuItem>
         <MenuItem key={"Brunei"} value="Brunei">Brunei</MenuItem>
         <MenuItem key={"Bulgaria"} value="Bulgaria">Bulgaria</MenuItem>
         <MenuItem key={"Burkina"} value="Burkina Faso">Burkina Faso</MenuItem>
         <MenuItem key={"Burundi"} value="Burundi">Burundi</MenuItem>
         <MenuItem key={"Cambodia"} value="Cambodia">Cambodia</MenuItem>
         <MenuItem key={"Cameroon"} value="Cameroon">Cameroon</MenuItem>
         <MenuItem key={"Canada"} value="Canada">Canada</MenuItem>
         <MenuItem key={"Canary"} value="Canary Islands">Canary Islands</MenuItem>
         <MenuItem key={"Cape"} value="Cape Verde">Cape Verde</MenuItem>
         <MenuItem key={"Cayman"} value="Cayman Islands">Cayman Islands</MenuItem>
         <MenuItem key={"Central"} value="Central African Republic">Central African Republic</MenuItem>
         <MenuItem key={"Chad"} value="Chad">Chad</MenuItem>
         <MenuItem key={"Channel"} value="Channel Islands">Channel Islands</MenuItem>
         <MenuItem key={"Chile"} value="Chile">Chile</MenuItem>
         <MenuItem key={"China"} value="China">China</MenuItem>
         <MenuItem key={"Christmas"} value="Christmas Island">Christmas Island</MenuItem>
         <MenuItem key={"Cocos"} value="Cocos Island">Cocos Island</MenuItem>
         <MenuItem key={"Colombia"} value="Colombia">Colombia</MenuItem>
         <MenuItem key={"Comoros"} value="Comoros">Comoros</MenuItem>
         <MenuItem key={"Congo"} value="Congo">Congo</MenuItem>
         <MenuItem key={"Cook"} value="Cook Islands">Cook Islands</MenuItem>
         <MenuItem key={"Costa"} value="Costa Rica">Costa Rica</MenuItem>
         <MenuItem key={"Cote"} value="Cote DIvoire">Cote DIvoire</MenuItem>
         <MenuItem key={"Croatia"} value="Croatia">Croatia</MenuItem>
         <MenuItem key={"Cuba"} value="Cuba">Cuba</MenuItem>
         <MenuItem key={"Curaco"} value="Curaco">Curacao</MenuItem>
         <MenuItem key={"Cyprus"} value="Cyprus">Cyprus</MenuItem>
         <MenuItem key={"Czech"} value="Czech Republic">Czech Republic</MenuItem>
         <MenuItem key={"Denmark"} value="Denmark">Denmark</MenuItem>
         <MenuItem key={"Djibouti"} value="Djibouti">Djibouti</MenuItem>
         <MenuItem key={"Dominica"} value="Dominica">Dominica</MenuItem>
         <MenuItem key={"Dominican"} value="Dominican Republic">Dominican Republic</MenuItem>
         <MenuItem key={"East"} value="East Timor">East Timor</MenuItem>
         <MenuItem key={"Ecuador"} value="Ecuador">Ecuador</MenuItem>
         <MenuItem key={"Egypt"} value="Egypt">Egypt</MenuItem>
         <MenuItem key={"El"} value="El Salvador">El Salvador</MenuItem>
         <MenuItem key={"Equatorial"} value="Equatorial Guinea">Equatorial Guinea</MenuItem>
         <MenuItem key={"Eritrea"} value="Eritrea">Eritrea</MenuItem>
         <MenuItem key={"Estonia"} value="Estonia">Estonia</MenuItem>
         <MenuItem key={"Ethiopia"} value="Ethiopia">Ethiopia</MenuItem>
         <MenuItem key={"Falkland"} value="Falkland Islands">Falkland Islands</MenuItem>
         <MenuItem key={"Faroe"} value="Faroe Islands">Faroe Islands</MenuItem>
         <MenuItem key={"Fiji"} value="Fiji">Fiji</MenuItem>
         <MenuItem key={"Finland"} value="Finland">Finland</MenuItem>
         <MenuItem key={"France"} value="France">France</MenuItem>
         <MenuItem key={"Frenchgui"} value="French Guiana">French Guiana</MenuItem>
         <MenuItem key={"Frenchpoly"} value="French Polynesia">French Polynesia</MenuItem>
         <MenuItem key={"Frenchsout"} value="French Southern Ter">French Southern Ter</MenuItem>
         <MenuItem key={"Gabon"} value="Gabon">Gabon</MenuItem>
         <MenuItem key={"Gambia"} value="Gambia">Gambia</MenuItem>
         <MenuItem key={"Georgia"} value="Georgia">Georgia</MenuItem>
         <MenuItem key={"Germany"} value="Germany">Germany</MenuItem>
         <MenuItem key={"Ghana"} value="Ghana">Ghana</MenuItem>
         <MenuItem key={"Gibraltar"} value="Gibraltar">Gibraltar</MenuItem>
         <MenuItem key={"Great"} value="Great Britain">Great Britain</MenuItem>
         <MenuItem key={"Greece"} value="Greece">Greece</MenuItem>
         <MenuItem key={"Greenland"} value="Greenland">Greenland</MenuItem>
         <MenuItem key={"Grenada"} value="Grenada">Grenada</MenuItem>
         <MenuItem key={"Guadeloupe"} value="Guadeloupe">Guadeloupe</MenuItem>
         <MenuItem key={"Guam"} value="Guam">Guam</MenuItem>
         <MenuItem key={"Guatemala"} value="Guatemala">Guatemala</MenuItem>
         <MenuItem key={"Guinea"} value="Guinea">Guinea</MenuItem>
         <MenuItem key={"Guyana"} value="Guyana">Guyana</MenuItem>
         <MenuItem key={"Haiti"} value="Haiti">Haiti</MenuItem>
         <MenuItem key={"Hawaii"} value="Hawaii">Hawaii</MenuItem>
         <MenuItem key={"Honduras"} value="Honduras">Honduras</MenuItem>
         <MenuItem key={"Hong"} value="Hong Kong">Hong Kong</MenuItem>
         <MenuItem key={"Hungary"} value="Hungary">Hungary</MenuItem>
         <MenuItem key={"Iceland"} value="Iceland">Iceland</MenuItem>
         <MenuItem key={"Indonesia"} value="Indonesia">Indonesia</MenuItem>
         <MenuItem key={"India"} value="India">India</MenuItem>
         <MenuItem key={"Iran"} value="Iran">Iran</MenuItem>
         <MenuItem key={"Iraq"} value="Iraq">Iraq</MenuItem>
         <MenuItem key={"Ireland"} value="Ireland">Ireland</MenuItem>
         <MenuItem key={"Isle"} value="Isle of Man">Isle of Man</MenuItem>
         <MenuItem key={"Israel"} value="Israel">Israel</MenuItem>
         <MenuItem key={"Italy"} value="Italy">Italy</MenuItem>
         <MenuItem key={"Jamaica"} value="Jamaica">Jamaica</MenuItem>
         <MenuItem key={"Japan"} value="Japan">Japan</MenuItem>
         <MenuItem key={"Jordan"} value="Jordan">Jordan</MenuItem>
         <MenuItem key={"Kazakhstan"} value="Kazakhstan">Kazakhstan</MenuItem>
         <MenuItem key={"Kenya"} value="Kenya">Kenya</MenuItem>
         <MenuItem key={"Kiribati"} value="Kiribati">Kiribati</MenuItem>
         <MenuItem key={"KoreaN"} value="Korea North">Korea North</MenuItem>
         <MenuItem key={"KoreaS"} value="Korea South">Korea South</MenuItem>
         <MenuItem key={"Kuwait"} value="Kuwait">Kuwait</MenuItem>
         <MenuItem key={"Kyrgyzstan"} value="Kyrgyzstan">Kyrgyzstan</MenuItem>
         <MenuItem key={"Laos"} value="Laos">Laos</MenuItem>
         <MenuItem key={"Latvia"} value="Latvia">Latvia</MenuItem>
         <MenuItem key={"Lebanon"} value="Lebanon">Lebanon</MenuItem>
         <MenuItem key={"Lesotho"} value="Lesotho">Lesotho</MenuItem>
         <MenuItem key={"Liberia"} value="Liberia">Liberia</MenuItem>
         <MenuItem key={"Libya"} value="Libya">Libya</MenuItem>
         <MenuItem key={"Liechtenstein"} value="Liechtenstein">Liechtenstein</MenuItem>
         <MenuItem key={"Lithuania"} value="Lithuania">Lithuania</MenuItem>
         <MenuItem key={"Luxembourg"} value="Luxembourg">Luxembourg</MenuItem>
         <MenuItem key={"Macau"} value="Macau">Macau</MenuItem>
         <MenuItem key={"Macedonia"} value="Macedonia">Macedonia</MenuItem>
         <MenuItem key={"Madagascar"} value="Madagascar">Madagascar</MenuItem>
         <MenuItem key={"Malaysia"} value="Malaysia">Malaysia</MenuItem>
         <MenuItem key={"Malawi"} value="Malawi">Malawi</MenuItem>
         <MenuItem key={"Maldives"} value="Maldives">Maldives</MenuItem>
         <MenuItem key={"Mali"} value="Mali">Mali</MenuItem>
         <MenuItem key={"Malta"} value="Malta">Malta</MenuItem>
         <MenuItem key={"Marshall"} value="Marshall Islands">Marshall Islands</MenuItem>
         <MenuItem key={"Martinique"} value="Martinique">Martinique</MenuItem>
         <MenuItem key={"Mauritania"} value="Mauritania">Mauritania</MenuItem>
         <MenuItem key={"Mauritius"} value="Mauritius">Mauritius</MenuItem>
         <MenuItem key={"Mayotte"} value="Mayotte">Mayotte</MenuItem>
         <MenuItem key={"Mexico"} value="Mexico">Mexico</MenuItem>
         <MenuItem key={"Midway"} value="Midway Islands">Midway Islands</MenuItem>
         <MenuItem key={"Moldova"} value="Moldova">Moldova</MenuItem>
         <MenuItem key={"Monaco"} value="Monaco">Monaco</MenuItem>
         <MenuItem key={"Mongolia"} value="Mongolia">Mongolia</MenuItem>
         <MenuItem key={"Montserrat"} value="Montserrat">Montserrat</MenuItem>
         <MenuItem key={"Morocco"} value="Morocco">Morocco</MenuItem>
         <MenuItem key={"Mozambique"} value="Mozambique">Mozambique</MenuItem>
         <MenuItem key={"Myanmar"} value="Myanmar">Myanmar</MenuItem>
         <MenuItem key={"Nambia"} value="Nambia">Nambia</MenuItem>
         <MenuItem key={"Nauru"} value="Nauru">Nauru</MenuItem>
         <MenuItem key={"Nepal"} value="Nepal">Nepal</MenuItem>
         <MenuItem key={"Netherland"} value="Netherland Antilles">Netherland Antilles</MenuItem>
         <MenuItem key={"Netherlands"} value="Netherlands">Netherlands (Holland, Europe)</MenuItem>
         <MenuItem key={"Nevis"} value="Nevis">Nevis</MenuItem>
         <MenuItem key={"Newcale"} value="New Caledonia">New Caledonia</MenuItem>
         <MenuItem key={"Newzea"} value="New Zealand">New Zealand</MenuItem>
         <MenuItem key={"Nicaragua"} value="Nicaragua">Nicaragua</MenuItem>
         <MenuItem key={"Niger"} value="Niger">Niger</MenuItem>
         <MenuItem key={"Nigeria"} value="Nigeria">Nigeria</MenuItem>
         <MenuItem key={"Niue"} value="Niue">Niue</MenuItem>
         <MenuItem key={"Norfolk"} value="Norfolk Island">Norfolk Island</MenuItem>
         <MenuItem key={"Norway"} value="Norway">Norway</MenuItem>
         <MenuItem key={"Oman"} value="Oman">Oman</MenuItem>
         <MenuItem key={"Pakistan"} value="Pakistan">Pakistan</MenuItem>
         <MenuItem key={"Palau"} value="Palau Island">Palau Island</MenuItem>
         <MenuItem key={"Palestine"} value="Palestine">Palestine</MenuItem>
         <MenuItem key={"Panama"} value="Panama">Panama</MenuItem>
         <MenuItem key={"Papua"} value="Papua New Guinea">Papua New Guinea</MenuItem>
         <MenuItem key={"Paraguay"} value="Paraguay">Paraguay</MenuItem>
         <MenuItem key={"Peru"} value="Peru">Peru</MenuItem>
         <MenuItem key={"Phillipines"} value="Phillipines">Philippines</MenuItem>
         <MenuItem key={"Pitcairn"} value="Pitcairn Island">Pitcairn Island</MenuItem>
         <MenuItem key={"Poland"} value="Poland">Poland</MenuItem>
         <MenuItem key={"Portugal"} value="Portugal">Portugal</MenuItem>
         <MenuItem key={"Puerto"} value="Puerto Rico">Puerto Rico</MenuItem>
         <MenuItem key={"Qatar"} value="Qatar">Qatar</MenuItem>
         <MenuItem key={"Republicmont"} value="Republic of Montenegro">Republic of Montenegro</MenuItem>
         <MenuItem key={"Republicserb"} value="Republic of Serbia">Republic of Serbia</MenuItem>
         <MenuItem key={"Reunion"} value="Reunion">Reunion</MenuItem>
         <MenuItem key={"Romania"} value="Romania">Romania</MenuItem>
         <MenuItem key={"Russia"} value="Russia">Russia</MenuItem>
         <MenuItem key={"Rwanda"} value="Rwanda">Rwanda</MenuItem>
         <MenuItem key={"Stbart"} value="St Barthelemy">St Barthelemy</MenuItem>
         <MenuItem key={"Steus"} value="St Eustatius">St Eustatius</MenuItem>
         <MenuItem key={"Sthel"} value="St Helena">St Helena</MenuItem>
         <MenuItem key={"Stkitt"} value="St Kitts-Nevis">St Kitts-Nevis</MenuItem>
         <MenuItem key={"Stlucia"} value="St Lucia">St Lucia</MenuItem>
         <MenuItem key={"Stmaar"} value="St Maarten">St Maarten</MenuItem>
         <MenuItem key={"Stpieer"} value="St Pierre & Miquelon">St Pierre & Miquelon</MenuItem>
         <MenuItem key={"Stvince"} value="St Vincent & Grenadines">St Vincent & Grenadines</MenuItem>
         <MenuItem key={"Saipan"} value="Saipan">Saipan</MenuItem>
         <MenuItem key={"Samoa"} value="Samoa">Samoa</MenuItem>
         <MenuItem key={"SamoaAmerica"} value="Samoa American">Samoa American</MenuItem>
         <MenuItem key={"San"} value="San Marino">San Marino</MenuItem>
         <MenuItem key={"Sao"} value="Sao Tome & Principe">Sao Tome & Principe</MenuItem>
         <MenuItem key={"Saudi"} value="Saudi Arabia">Saudi Arabia</MenuItem>
         <MenuItem key={"Senegal"} value="Senegal">Senegal</MenuItem>
         <MenuItem key={"Seychelles"} value="Seychelles">Seychelles</MenuItem>
         <MenuItem key={"Sierra"} value="Sierra Leone">Sierra Leone</MenuItem>
         <MenuItem key={"Singapore"} value="Singapore">Singapore</MenuItem>
         <MenuItem key={"Slovakia"} value="Slovakia">Slovakia</MenuItem>
         <MenuItem key={"Slovenia"} value="Slovenia">Slovenia</MenuItem>
         <MenuItem key={"Solomon"} value="Solomon Islands">Solomon Islands</MenuItem>
         <MenuItem key={"Somalia"} value="Somalia">Somalia</MenuItem>
         <MenuItem key={"South"} value="South Africa">South Africa</MenuItem>
         <MenuItem key={"Spain"} value="Spain">Spain</MenuItem>
         <MenuItem key={"Sri"} value="Sri Lanka">Sri Lanka</MenuItem>
         <MenuItem key={"Sudan"} value="Sudan">Sudan</MenuItem>
         <MenuItem key={"Suriname"} value="Suriname">Suriname</MenuItem>
         <MenuItem key={"Swaziland"} value="Swaziland">Swaziland</MenuItem>
         <MenuItem key={"Sweden"} value="Sweden">Sweden</MenuItem>
         <MenuItem key={"Switzerland"} value="Switzerland">Switzerland</MenuItem>
         <MenuItem key={"Syria"} value="Syria">Syria</MenuItem>
         <MenuItem key={"Tahiti"} value="Tahiti">Tahiti</MenuItem>
         <MenuItem key={"Taiwan"} value="Taiwan">Taiwan</MenuItem>
         <MenuItem key={"Tajikistan"} value="Tajikistan">Tajikistan</MenuItem>
         <MenuItem key={"Tanzania"} value="Tanzania">Tanzania</MenuItem>
         <MenuItem key={"Thailand"} value="Thailand">Thailand</MenuItem>
         <MenuItem key={"Togo"} value="Togo">Togo</MenuItem>
         <MenuItem key={"Tokelau"} value="Tokelau">Tokelau</MenuItem>
         <MenuItem key={"Tonga"} value="Tonga">Tonga</MenuItem>
         <MenuItem key={"Trinidad"} value="Trinidad & Tobago">Trinidad & Tobago</MenuItem>
         <MenuItem key={"Tunisia"} value="Tunisia">Tunisia</MenuItem>
         <MenuItem key={"Turkey"} value="Turkey">Turkey</MenuItem>
         <MenuItem key={"Turkmenistan"} value="Turkmenistan">Turkmenistan</MenuItem>
         <MenuItem key={"Turks"} value="Turks & Caicos Is">Turks & Caicos Is</MenuItem>
         <MenuItem key={"Tuvalu"} value="Tuvalu">Tuvalu</MenuItem>
         <MenuItem key={"Uganda"} value="Uganda">Uganda</MenuItem>
         <MenuItem key={"United"} value="United Kingdom">United Kingdom</MenuItem>
         <MenuItem key={"Ukraine"} value="Ukraine">Ukraine</MenuItem>
         <MenuItem key={"UAE"} value="United Arab Erimates">United Arab Emirates</MenuItem>
         <MenuItem key={"UnitedStates"} value="United States of America">United States of America</MenuItem>
         <MenuItem key={"Uraguay"} value="Uraguay">Uruguay</MenuItem>
         <MenuItem key={"Uzbekistan"} value="Uzbekistan">Uzbekistan</MenuItem>
         <MenuItem key={"Vanuatu"} value="Vanuatu">Vanuatu</MenuItem>
         <MenuItem key={"Vatican"} value="Vatican City State">Vatican City State</MenuItem>
         <MenuItem key={"Venezuela"} value="Venezuela">Venezuela</MenuItem>
         <MenuItem key={"Vietnam"} value="Vietnam">Vietnam</MenuItem>
         <MenuItem key={"Virginbrit"} value="Virgin Islands (Brit)">Virgin Islands (Brit)</MenuItem>
         <MenuItem key={"Virgin"} value="Virgin Islands (USA)">Virgin Islands (USA)</MenuItem>
         <MenuItem key={"Wake"} value="Wake Island">Wake Island</MenuItem>
         <MenuItem key={"Wallis"} value="Wallis & Futana Is">Wallis & Futana Is</MenuItem>
         <MenuItem key={"Yemen"} value="Yemen">Yemen</MenuItem>
         <MenuItem key={"Zaire"} value="Zaire">Zaire</MenuItem>
         <MenuItem key={"Zambia"} value="Zambia">Zambia</MenuItem>
         <MenuItem key={"Zimbabwe"} value="Zimbabwe">Zimbabwe</MenuItem>
      </Select>
   )
}