import { IconButton, Tooltip } from "@material-ui/core";
import React from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { showCloneDialog } from "../../../store/features/dialogs";

const useStyles = makeStyles((theme) => ({
  tableCell: {
    // padding: theme.spacing(-1)
  },
  icon: {
    "& svg": {
      fontSize: 2,
    },
  },
  button: {
    maxHeight: 25,
  },
}));

const CloneAction = ({ job, forceNew }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <Tooltip title="Edit Input">
      <IconButton
        className={classes.button}
        color="primary"
        variant="outlined"
        size="small"
        onClick={() => {
          dispatch(showCloneDialog(job, forceNew));
        }}
      >
        <EditIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};

export default CloneAction;
