import Axios from "axios";


export const GetRevisions = (
  id,
  setJobs
) => {
  return Axios.get(`/api/revisions/${id}`, {})
  .then(res => {
    setJobs(res.data.jobs)
  }).catch(err => {
    console.log(err)
  })
}

export const RunRedesign = (
  id
) => {
  return Axios.post(`/api/revisions/run/${id}`)
  .then(() => {})
  .catch(err => {
    console.log(err)
  })
}

export const OverwriteOriginal = (
  id
) => {
  return Axios.post(`/api/revisions/overwrite/${id}`)
  .then(() => {})
  .catch(err => {
    console.log(err)
  })
}
