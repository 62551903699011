import React, { useState, useEffect, useRef } from "react";
import SignUpErrors from "./_SignUpErrors";
import { FormControl, Select, MenuItem } from '@material-ui/core';
import PasswordStrengthBar from 'react-password-strength-bar';
import { Country } from "./_CountrySelect";
import { login, signUp } from "../../auth";
import { CreateUser } from "../../services/Login";
import reCAPTCHA from "react-google-recaptcha";

import Reaptcha from 'reaptcha';

const SignUp = ({
  setMode,
  Mode,
  RegStatus,
  RegMessage,
  resetForm
}) => {


  const [Email, setEmail] = useState("");
  const [Name, setName] = useState("");
  const [Password, setPassword] = useState("");
  const [Occupation, setOccupation] = useState("");
  const [Region, setRegion] = useState("");
  const [PhoneNumber, setPhoneNumber] = useState("");

  const [AcceptedTerms, setAcceptedTerms] = useState(false)
  const [FormFilled, setFormFilled] = useState(false)

  const [captchaToken, setCaptchaToken] = useState(null);
  const captchaRef = useRef(null);



  useEffect(() => {
    if (Email && Name && Password && Occupation && Region && PhoneNumber) {
      setFormFilled(true)
    } else {
      setFormFilled(false)
    }
  }, [Email, Name, Password, Occupation, Region, PhoneNumber])

  const handleCreate = () => {
    if (AcceptedTerms && FormFilled) {
      signUp(Email, Password)
        .then((user) => {
          return CreateUser(
            Email,
            Password,
            Occupation,
            Region,
            PhoneNumber,
            user.uid
          )
        }).then((res) => {
          console.log('create user was a success')
          login(Email, Password)
        }).catch((err) => {
          console.log(err)
        })
    }
  }

  const verify = () => {
    console.log('captcha verified')
    console.log(captchaToken)
    console.log(!captchaToken)
    captchaRef.current.getResponse().then(res => {
      setCaptchaToken(res)
    })
  }

  return (
    <div className="sub-cont">
      <div className="img">
        <div className="img__text m--up">
          <img
            src="https://daisy.ai/assets/img/logo.png"
            alt="Daisy Logo"
            className="daisy-logo"
          />
          <p>
            Daisy is the first fully automated construction design system
            powered by Artificial Intelligence.
          </p>
        </div>
        <div className="img__text m--in">
          <img
            src="https://daisy.ai/assets/img/logo.png"
            alt="Daisy Logo"
            className="daisy-logo"
          />
          <h2>One of us?</h2>
          <p>If you already have an account, just sign in.</p>
        </div>
        <div
          className="img__btn"
          onClick={e => {
            e.preventDefault();
            resetForm();
            setMode(Mode === "login" ? "register" : "login");
          }}
        >
          <span className="m--up">Sign Up</span>
          <span className="m--in">Sign In</span>
        </div>
      </div>
      <div className="form sign-up">
        <h2>New User Sign up</h2>
        <label>
          <span>Email*</span>
          <input
            type="email"
            value={Email}
            onChange={e => setEmail(e.target.value)}
          />
        </label>
        <label>
          <span>Full Name*</span>
          <input
            type="text"
            value={Name}
            onChange={e => setName(e.target.value)}
          />
        </label>
        <label>
          <span>Password*</span>
          {/* TODO: Add new password strength library */}
          <input
            type="text"
            value={Password}
            onChange={e => setPassword(e.target.value)}
          />
          <PasswordStrengthBar password={Password} />
        </label>
        <label>
          <span>Occupation*</span>
          <FormControl fullWidth>
            <Select
              value={Occupation}
              onChange={e => setOccupation(e.target.value)}
            >
              {[
                'Architect',
                'Builder',
                'Civil Engineer',
                'Design Manager',
                'Engineer',
                'Floor Designer',
                'I-Joist Designer',
                'Product Manufacturer',
                'Structural Engineer',
                'Other'
              ].map((option, index) => (
                <MenuItem key={index} value={option}>{option}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </label>
        <label>
          <span>Country*</span>
          <FormControl fullWidth>
            <Country
              {...{
                Region,
                setRegion
              }}
            />
          </FormControl>
        </label>
        <label>
          <span>Phone Number*</span>
          <input
            type="text"
            value={PhoneNumber}
            onChange={e => setPhoneNumber(e.target.value)}
          />
        </label>
        <reCAPTCHA 
          siteky={process.env.RECAPTCHA_SITE_KEY}
        />
        {RegMessage && RegStatus !== "Clean" && (
          <div className="text-center">
            <div
              className={
                "alert alert-" +
                RegStatus +
                " p-2 mt-5 text-center d-inline-block"
              }
              role="alert"
            >
              <SignUpErrors errors={RegMessage.messages} />
            </div>
          </div>
        )}
        <label>
          <span>I agree to the
            <a href='/daisy_terms.pdf' without rel='noopener noreferrer' target='_blank'> terms and conditions </a>
            and
            <a href='/daisy_privacy_policy.pdf' without rel='noopener noreferrer' target='_blank'> privacy policy</a>
          </span>
          <input
            name="isGoing"
            type="checkbox"
            checked={AcceptedTerms}
            onChange={() => {
              setAcceptedTerms(!AcceptedTerms)
            }} />
        </label>
        <label>
          <Reaptcha
            sitekey="6LdS-1cpAAAAAO1x_z0UZRJcI7lQGJzAm505-RlU"
            ref={captchaRef}
            onVerify={verify}
          />
        </label>
        <button
          type="button"
          className="submit"
          onClick={handleCreate}
          disabled={!AcceptedTerms || !FormFilled || !captchaToken}
          style={{
            backgroundColor: AcceptedTerms && FormFilled && captchaToken ? '#155b86' : '#999999'
          }}
        >
          Sign Up
        </button>
      </div>
    </div>
  );
};

export default SignUp;
