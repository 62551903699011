//Slice

import { createSlice } from "@reduxjs/toolkit";
import Axios from "axios";
import { updateSelected } from "./dashboard";
import { addLoad, removeLoad } from "./loading";

export const dialogSlice = createSlice({
  name: "dialog",
  initialState: {
    dialog_type: null,
    value: [],
  },
  reducers: {
    showDialog: (state, action) => {
      state.dialog_type = action.payload.type;
      state.value = action.payload.value;
    },
    hide: (state, action) => {
      state.dialog_type = null;
      state.value = [];
    },
  },
});

export default dialogSlice.reducer;

// Actions

const { showDialog, hide } = dialogSlice.actions;

export const hideDialog = () => (dispatch) => {
  dispatch(hide(null));
  dispatch(updateSelected([]))
};

export const showExpiredDemoDialog = () => (dispatch) => {
  dispatch(
    showDialog({
      type: "expired",
      value: [],
    })
  );
};

export const showDeleteDialog = (ids) => (dispatch) => {
  dispatch(
    showDialog({
      type: "delete",
      value: ids,
    })
  );
};

export const showRunDialog = (ids) => (dispatch) => {
  dispatch(
    showDialog({
      type: "run",
      value: ids,
    })
  );
};

export const showArchiveDialog = (ids) => (dispatch) => {
  dispatch(
    showDialog({
      type: "archive",
      value: ids,
    })
  );
};

export const showEditDialog = (id, input) => (dispatch) => {
  dispatch(
    showDialog({
      type: "edit",
      value: {
        id: id,
        input: input,
      },
    })
  );
};

export const showOnboardingDialog = () => (dispatch) => {
  dispatch(
    showDialog({
      type: "onboard",
      value: [],
    })
  );
};

export const showSaveDialog = () => (dispatch) => {
  dispatch(
    showDialog({
      type: "save",
      value: [],
    })
  );
};

export const showConfirmSaveDialog = (id) => (dispatch) => {
  dispatch(
    showDialog({
      type: "confirmsave",
      value: id,
    })
  );
};

export const showCloneDialog =
  (job, forceNew = false) =>
  (dispatch) => {
    dispatch(
      showDialog({
        type: "clone",
        value: {
          job: job,
          forceNew: forceNew,
        },
      })
    );
  };

export const showDownloadDialog =
  (job_id, isRevision = false) =>
  (dispatch) => {
    Axios.get(`/api/jobs/${job_id}`, {})
      .then((res) => {
        let job = res.data.job;
        dispatch(
          showDialog({
            type: "download",
            value: {
              job: job,
              isRevision: isRevision,
            },
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

export const showWorkerSelectionDialog = (job_id) => (dispatch) => {
  dispatch(addLoad("loading workers"))
  Axios.get(`/api/worker/${job_id}`)
  .then(res => {
    const data = res.data.workers;
    dispatch(removeLoad());
    dispatch(
      showDialog({
        type: "workerSelect",
        value: {
          job: job_id,
          data: data,
        },
      })
    );
  }).catch(err => {
    dispatch(removeLoad());
    if (err.response.status === 400) {
      dispatch(
        showDialog({
          type: "workerSelect",
          value: {
            job: job_id,
            data: [],
          },
        })
      );
    }

    console.log(err)
  });
}