import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Box, Tooltip } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import AutorenewIcon from '@material-ui/icons/Autorenew';
import ListIcon from "@material-ui/icons/List";
import {  useNavigate } from "react-router-dom";
import { RunRedesign } from "../../services/Revisions";
import { saveJob } from "../../store/features/cad";
import { useDispatch, useSelector } from "react-redux";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import WarningIcon from '@material-ui/icons/Warning';


const useStyles = makeStyles((theme) => ({
  div: {
    display: "flex",
    paddingRight: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const ActionBar = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    job,
    enableSave,
    enableRedesign,
    enableRun, runJob
  } = props;
  const { errors, lastSaved } = useSelector(state => state.cad)

  if (!job) return null;

  return (
    <div className={classes.div}>
      <Box container style={{ marginLeft: '8px', marginRight: '8px' }}>
        <Typography variant='subtitle1'>{`ID: ${job ? job.id : ''}`}</Typography>
        <Typography variant='subtitle1'>{`Reference: ${job ? job.name : ''}`}</Typography>
      </Box>
      <Box container style={{ marginLeft: '8px' }}>
        <Typography variant='subtitle1'>{`Status: ${job ? job.status : ''}`}</Typography>
        <Typography variant='subtitle1'>{`User: ${job ? job.username : ''}`}</Typography>
      </Box>
      <div style={{ flex: 1 }} />
      {(job.is_revision || job.status === "SUCCEEDED") && (
        <Button
          id="revisions-button"
          variant="contained"
          size="small"
          color="default"
          className={classes.button}
          startIcon={<ListIcon style={{ color: "#1F5C86" }} />}
          onClick={() => {
            if (job.is_revision) {
              navigate(`/jobs/view/${job.parent}`)
            } else {
              navigate(`/jobs/view/${job.id}`)
            }
          }}
        >
          View Revisions
        </Button>
      )}
      <Button
        id="save-button"
        variant="contained"
        size="small"
        color="default"
        disabled={!enableSave}
        className={classes.button}
        startIcon={<SaveIcon style={{ color: "#1F5C86" }} />}
        onClick={() => {
          dispatch(saveJob(true));
        }}
      >
        Save
      </Button>
      <Button
        id="save-button"
        variant="contained"
        size="small"
        color="default"
        disabled={!enableRedesign}
        className={classes.button}
        startIcon={<AutorenewIcon style={{ color: "#1F5C86" }} />}
        onClick={() => {
          RunRedesign(job.id)
          navigate(`/jobs/view/${job.parent}`)
        }}
      >
        Redesign
      </Button>
      <Button
        id="run-button"
        variant="contained"
        size="small"
        color="default"
        disabled={!enableRun}
        className={classes.button}
        startIcon={<PlayArrowIcon style={{ color: "#1F5C86" }} />}
        onClick={() => {
          runJob();
        }}
      >
        Design Floor
      </Button>
      <Box container style={{ marginLeft: '8px', marginTop: '16px' }}>
        {(errors.critical.length > 0) && (
          <Tooltip title={`Your design contains critical errors. \n Please go to the Check Project Tab`} arrow position='bottom'>
            <ErrorOutlineIcon style={{ color: "#990000" }} />
          </Tooltip>
        )} 
        {(errors.critical.length === 0) && (errors.warnings.length > 0) && (
          <Tooltip title={`Your design contains non critical errors. \n Please go to the Check Project Tab`} arrow position='bottom'>
            <WarningIcon style={{ color: "#eed202" }} />
          </Tooltip>
        )} 
        {(errors.warnings.length === 0) && (errors.critical.length === 0) && (
          <Tooltip title={lastSaved}>
            <CloudDoneIcon style={{ color: "#1F5C86" }} />
          </Tooltip>
        )}
      </Box>
    </div>
  );
};

export default ActionBar;
