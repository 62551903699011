import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Table, TableRow, TableCell, TableBody } from "@material-ui/core";
import { Card, CardHeader, CardContent } from "@material-ui/core";
import { ActionCell } from "./ActionCell";
import { GetRevisions } from "../../services/Revisions";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  body: {
    flex: true,
    minHeight: 0,
    margin: theme.spacing(2),
    marginTop: theme.spacing(0),
  },
  tableCell: {
    backgroundColor: "#CCCCC",
  },
}));

const DateFormatter = (value) => {
  return moment
    .utc(value, "YYYY-MM-DDThh:mm:ss")
    .local()
    .format("YYYY-MM-DD HH:mm:ss");
};

export const RevisionTable = (props) => {
  const classes = useStyles();
  const { Job } = props;
  const [Jobs, setJobs] = useState([]);

  useEffect(() => {
    if (Job) {
      GetRevisions(Job.id, setJobs);
    }
    const interval = setInterval(() => {
      if (Job) {
        GetRevisions(Job.id, setJobs);
      }
    }, 20000);

    return () => clearInterval(interval);
  }, [Job]);

  return (
    <div className={classes.body}>
      <Card>
        <CardHeader title="Revisions" />
        <CardContent>
          {Job && (
            <Table>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Reference</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Created</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
              <TableBody>
                {Jobs.map((child) => {
                  return (
                    <TableRow>
                      <TableCell>{child.id}</TableCell>
                      <TableCell>{child.name}</TableCell>
                      <TableCell>{child.status}</TableCell>
                      <TableCell>{DateFormatter(child.created_at)}</TableCell>
                      <ActionCell row={child} />
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </CardContent>
      </Card>
    </div>
  );
};
