import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SignUpErrors from "./_SignUpErrors"
import { ResetPassword as Reset } from '../../services/Login';


const ResetPassword = () => {
    const navigate = useNavigate()
    const { Token } = useParams();
    
    const [Password, setPassword] = useState('')
    const [RegStatus, setRegStatus] = useState('')

    return (
        <>
            <div className="Login d-flex justify-content-center align-items-center">
                <div className="Login-Window">
                    <div className="form thanks">
                        <h2>Reset Password</h2>
                        <h4 className="my-3">
                            {RegStatus === "success" ? (
                                "Password has been reset"   
                            ) : (
                                "Please Enter Your New Password"
                            )}
                            
                        </h4>
                        {RegStatus !== "success" ? (
                            <div>
                                <label>
                                    <span className="password">New Password</span>
                                    <input
                                    type="password"
                                    value={Password}
                                    onChange={e => setPassword(e.target.value)}
                                    />
                                </label>
                                <button
                                    type="button"
                                    className="submit"
                                    onClick={e => {
                                        Reset(Token, Password)
                                        .then(res => {
                                            setRegStatus("success")
                                        })
                                        .catch(err => {
                                            console.log(err)
                                        })
                                    }}
                                >
                                    Update Password
                                </button>
                            </div>
                        ): (null) }
                        <button 
                            type="button"
                            className="forgot-pass"
                            onClick={e => navigate('/login')}
                        >
                            Back to Login
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResetPassword;