import React from "react";
import { useDispatch } from "react-redux";
import { makeStyles, Typography, Button } from "@material-ui/core";
import { CreateRevision } from "../../services/Jobs";
import { showDownloadDialog, showEditDialog } from "../../store/features/dialogs";
import { DownloadOutput } from "../../services/Downloads";


const useStyles = makeStyles((theme) => ({
  body: {
    flex: true,
    margin: theme.spacing(2),
    marginTop: theme.spacing(0),
  },
  tableCell: {
    backgroundColor: '#CCCCC',
  },
  button: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  griditem: {
    marginBottom: theme.spacing(2),
  }
}));

const OutputActions = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { Job } = props;

  if (Job == null) {
    return null;
  }

  return (
    <>
      <Typography 
        className={classes.griditem} 
        variant='subtitle1'
      >
        Output Actions
      </Typography>
      <Button 
        className={classes.button} 
        variant='contained' 
        color='primary'
        onClick={() => {
          dispatch(showEditDialog(Job.id, false));
        }}
      >
        View Output File
      </Button>
      <Button 
        className={classes.button} 
        variant='contained' 
        color='primary'
        onClick={() => {
          CreateRevision(Job.id).then((res) => {
            dispatch(showEditDialog(res.data.job_id, false));
          }).catch(err => {
            console.log(err)
          });
        }}
      >
        Revise Design
      </Button>
      <Button 
        className={classes.button} 
        variant='contained'
        color='primary'
        onClick={() => {
          dispatch(showDownloadDialog(Job.id))
        }}
      >
        Download Output
      </Button>
      {Job.revisions > 0 && (
        <Button 
          className={classes.button} 
          variant='contained'
          color='primary'
          onClick={() => {
            DownloadOutput(Job.id, 'original')
          }}
        >
          Download Original Output
        </Button>
      )}
    </>
  );
};

export default OutputActions;
