import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { hideDialog } from '../../store/features/dialogs';
import { getJobs } from '../../store/features/jobs';

function SavingDialog() {

  const { dialog_type } = useSelector((state) => state.dialogs)
  const { admin } = useSelector((state) => state.user)
  const dispatch = useDispatch();

  return (
    <Dialog 
      maxWidth='sm'
      fullWidth
      open={dialog_type === 'save'}
    >
      <DialogTitle>
        Exiting to Dashboard
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          All unsaved content will be lost. Are you sure you wish to exit?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button 
          variant='contained'
          onClick={() => {
            dispatch(getJobs(admin))
            dispatch(hideDialog())
          }}
        >
          Cancel
        </Button>
        <Button 
          variant='contained'
          component={Link}
          to='/jobs'
          onClick={() => {
            dispatch(getJobs(admin))             
            dispatch(hideDialog())
          }}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SavingDialog;