import { IconButton, Tooltip } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core";
import LaunchIcon from "@material-ui/icons/Launch";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  tableCell: {
    // padding: theme.spacing(-1)
  },
  icon: {
    "& svg": {
      fontSize: 2,
    },
  },
  button: {
    maxHeight: 25,
  },
}));

const ViewAction = ({ job }) => {
  const classes = useStyles();

  return (
    <Tooltip title="View Output">
      <IconButton
        className={classes.button}
        color="primary"
        variant="outlined"
        size="small"
        component={Link}
        to={`/jobs/cad/${job.id}?input=false`}
      >
        <LaunchIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};

export default ViewAction;
