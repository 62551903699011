import React from "react";

const Forgot = ({ 
  handleCloseForgot,
  handleSubmitForgot,
  forgotEmail,
  setForgotEmail,
  forgotMessage
}) => {
  return (
    <div
      className="modal d-block"
      tabIndex="-1"
      role="dialog"
      onClick={handleCloseForgot}
    >
      <div
        className="modal-dialog"
        role="document"
        onClick={e => e.stopPropagation()}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Forgot Password</h5>
            <button
              type="button"
              className="close"
              onClick={handleCloseForgot}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body text-center">
            <p className="m-0">Please enter your email.</p>
          </div>
          <div className="md-form px-3">
            <input 
              type="email"
              className="form-control validate"
              value={forgotEmail}
              onChange={e => setForgotEmail(e.target.value)}
            />
            <div className="alert mt-3">
              {forgotMessage}
            </div>
          </div>
          <div className="modal-footer justify-content-center">
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={handleSubmitForgot}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forgot;
