import { IconButton, Tooltip } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core";
import ListIcon from "@material-ui/icons/List"
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  tableCell: {
    // padding: theme.spacing(-1)
  },
  icon: {
    "& svg": {
      fontSize: 2,
    },
  },
  button: {
    maxHeight: 25,
  },
}));

const ViewRevisionAction = ({ job }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Tooltip title="View Revisions ">
      <IconButton
        className={classes.button}
        color="primary"
        variant="outlined"
        size="small"
        onClick={() => {
          navigate(`/jobs/view/${job.id}`);
        }}
      >
        <ListIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};

export default ViewRevisionAction;
