import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Grid } from "@material-ui/core";
import FormControl from "./FormControl";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const OptimisationOptions = ({
  NumAgents,
  setNumAgents,
  NumGenerations,
  setNumGenerations,
}) => {    
    const optimization = get_optimization(
      NumAgents, setNumAgents,
      NumGenerations, setNumGenerations,
    )
  
    return (
      <Accordion 
        variant='outlined'
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon/>}
        >
          Optimization Options
        </AccordionSummary>
        <AccordionDetails>
          <Grid >
            {
              optimization.map((f, key) => (
                <Grid key={key} item xs={12}>
                  <FormControl {...f} />
                </Grid>
              ))
            }
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  };

  const get_optimization = (
    NumAgents, setNumAgents,
    NumGenerations, setNumGenerations,
  ) => {
    return (
      [
        {
          Type: "number",
          Name: "num_agents",
          Label: "Number of agents",
          NoLabel: false,
          Row: true,
          max: 1000,
          min: 1,
          value: NumAgents,
          onChange: (event) => {
            setNumAgents(event.target.value);
          },
        },
        {
          Type: "number",
          Name: "Number of generations",
          Label: "Number of generations",
          NoLabel: false,
          Row: true,
          max: 100,
          min: 0,
          value: NumGenerations,
          onChange: (event) => {
            setNumGenerations(event.target.value);
          },
        },
      ]
    )
  } 

export default OptimisationOptions;