import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Axios from "axios";
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  InputAdornment,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";

const AccessToken = () => {
  const [Token, setToken] = useState("");
  const [Error, setError] = useState("");
  const [Open, setOpen] = useState(false);

  const getAccessToken = () => {
    Axios.get("/api/firebase/token")
      .then((res) => {
        setToken(res.data.access_token);
        setOpen(true);
        setError("");
      })
      .catch((err) => {
        console.log(err);
        setError("There was an error generating the token.");
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="container mt-3" style={{ paddingTop: "32px"}}>
      <div className="row">
        <div className="col-12">
          <Card>
            <CardHeader
              title="Generate an Access Token for 3rd Party Applications."
              subheader="Click the button below to generate a new access token."
            />
            <CardContent>
              <TextField
                variant="outlined"
                disabled
                fullWidth
                value={Token}
                style={{ marginBottom: "8px", marginTop: "8px" }}
                error={Error !== ""}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        variant="outlined"
                        disabled={Token === ""}
                        onClick={() => {
                          navigator.clipboard
                            .writeText(Token)
                            .then(() => {
                              console.log("copied");
                            })
                            .catch((err) => {
                              console.log(err);
                            });
                        }}
                      >
                        Copy
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => getAccessToken()}
              >
                Generate Token
              </Button>
              <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                open={Open}
                autoHideDuration={3000}
                onClose={handleClose}
                message="Copied to clipboard"
                key={"snackbar"}
              />
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default AccessToken;
