import React from "react";
import {
  Toolbar,
  Typography,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import RefreshIcon from "@material-ui/icons/Refresh";
import { lighten, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { getJobs } from "../../store/features/jobs";
import { showArchiveDialog, showDeleteDialog, showRunDialog } from "../../store/features/dialogs";

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const JobTableToolbar = () => {
  const classes = useToolbarStyles();
  const dispatch = useDispatch();
  const { selected } = useSelector((state) => state.dashboard);
  const { jobs } = useSelector((state) => state.jobs);
  const { admin } = useSelector((state) => state.user);
  const numSelected = selected.length;

  const selectedToIds = () => {
    let selectedIds = selected.map(index => {
      return jobs[index].id
    })

    return selectedIds
  }

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {"Jobs"}
        </Typography>
      )}
      {numSelected > 0 ? (
        <>
          <Tooltip title="Run">
            <IconButton
              aria-label="run"
              onClick={(e) => {
                dispatch(showRunDialog(selectedToIds()))
              }}
            >
              <i className="fa fa-play"></i>
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton
              aria-label="delete"
              onClick={(e) => {
                dispatch(showDeleteDialog(selectedToIds()))
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Archive">
            <IconButton
              aria-label="archive"
              onClick={() => {
                dispatch(showArchiveDialog(selectedToIds()))
              }}
            >
              <DownloadIcon />
            </IconButton>
          </Tooltip>
        </>
      ) : (null)
      }
      <Tooltip title="Refresh">
        <IconButton 
          aria-label="refresh" 
          onClick={() => {
            dispatch(getJobs(admin))
          }}>
          <RefreshIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
};

export default JobTableToolbar;
