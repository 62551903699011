import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import DesignOptions from "../Shared/Forms/_DesignOptions";
import OptimisationOptions from "../Shared/Forms/_OptimisationOptions";
import { RunJob } from "../../services/Jobs";
import { useDispatch, useSelector } from "react-redux";
import { hideDialog } from "../../store/features/dialogs";
import { getJobs } from "../../store/features/jobs";
import { useNavigate } from "react-router-dom";

const RunDialog = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { dialog_type, value } = useSelector((state) => state.dialogs)
  const { admin } = useSelector((state) => state.user)

  const [FloorPerformance, setFloorPerformance] = useState(-1);
  const [WastePrioritisation, setWastePrioritisation] = useState(0);
  const [ConstructionTimePrioritisation, setConstructionTimePrioritisation] =
    useState(1);
  const [NumGenerations, setNumGenerations] = useState(20);
  const [NumAgents, setNumAgents] = useState(100);
  const [NumWorkers, setNumWorkers] = useState(4);
  const [ConfigJson, setConfigJson] = useState("{}");


  const handleRunJob = () => {
      RunJob(
        value,
        FloorPerformance,
        WastePrioritisation,
        ConstructionTimePrioritisation,
        NumWorkers,
        NumAgents,
        NumGenerations,
        ConfigJson
      ).then(() => {
        dispatch(getJobs(admin))
        dispatch(hideDialog())
        navigate('/jobs')
      })
      .catch(() => {
        dispatch(getJobs(admin))
        dispatch(hideDialog())
      })
  }

    return (
        <Dialog open={dialog_type === 'run'} fullWidth maxWidth='lg'>
            <DialogTitle>Run</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {value.length < 2
                    ? "Selected 1 Job"
                    : `Selected ${value.length} Jobs`}
                </DialogContentText>
                <DesignOptions
                  FloorPerformance={FloorPerformance}
                  setFloorPerformance={setFloorPerformance}
                  WastePrioritisation={WastePrioritisation}
                  setWastePrioritisation={setWastePrioritisation}
                  ConstructionTimePrioritisation={ConstructionTimePrioritisation}
                  setConstructionTimePrioritisation={setConstructionTimePrioritisation}
                />
                {/* {User.user.is_superuser && ( */}
                { admin && (
                    <OptimisationOptions
                    NumAgents={NumAgents}
                    setNumAgents={setNumAgents}
                    NumGenerations={NumGenerations}
                    setNumGenerations={setNumGenerations}
                    NumWorkers={NumWorkers}
                    setNumWorkers={setNumWorkers}
                    ConfigJson={ConfigJson}
                    setConfigJson={setConfigJson}
                    />
                )}
                <DialogActions>
                    <Button
                        variant='contained'
                        color='default'
                        onClick={()=> {
                          dispatch(hideDialog())
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant='contained'
                        color='default'
                        onClick={handleRunJob}
                    >
                        Design Floor
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
  };

export default RunDialog;