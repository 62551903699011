import React from "react";
import WIPActions from "./ActionCells/WIPActions";
import SuccessActions from "./ActionCells/SuccessActions";
import FailedActions from "./ActionCells/FailedActions";
import RunningActions from "./ActionCells/RunningActions";

export const ActionCell = ({ row }) => {
  if (row.status === "SUCCEEDED") {
    return <SuccessActions row={row} />;
  } else if (row.status === "WIP") {
    return <WIPActions row={row} />;
  } else if (row.status === "FAILED") {
    return <FailedActions row={row} />;
  } else {
    return <RunningActions row={row} />;
  }
};
