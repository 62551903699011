import React from "react";
import RunDialog from "./RunDialog";
import EditDialog from "./EditDialog";
import ExpiredDemo from "./ExpiredDemo";
import DeleteDialog from "./DeleteDialog";
import ArchiveDialog from "./ArchiveDialog";
import OnboardingDialog from "./OnboardingDialog";
import SavingDialog from "./SavingDialog";
import CloneDialog from "./CloneDialog";
import DownloadDialog from "./DownloadDialog";
import ConfirmSaved from "./ConfirmSaved";
import WorkerSelectionDialog from "./WorkerSelectionDialog";

function DialogController() {
  return (
    <div>
      <ArchiveDialog />
      <ConfirmSaved />
      <DeleteDialog />
      <EditDialog />
      <ExpiredDemo />
      <RunDialog />
      <OnboardingDialog />
      <SavingDialog />
      <CloneDialog />
      <DownloadDialog />
      <WorkerSelectionDialog />
    </div>
  );
}

export default DialogController;
