import React from "react";

const SignUpErrors = ({errors}) => {
  return errors ? (errors.map((message) => (
      <div className="text-left">
          <ul>
              <p>{message}</p>
          </ul>
      </div>
    ))) : null
};

export default SignUpErrors;
