import { IconButton, Tooltip } from "@material-ui/core";
import React from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import { showDownloadDialog } from "../../../store/features/dialogs";

const useStyles = makeStyles((theme) => ({
  tableCell: {
    // padding: theme.spacing(-1)
  },
  icon: {
    "& svg": {
      fontSize: 2,
    },
  },
  button: {
    maxHeight: 25,
  },
}));

const DownloadAction = ({ job }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <Tooltip title="Download Files">
      <IconButton
        className={classes.button}
        color="primary"
        variant="outlined"
        size="small"
        disabled={!job.filename}
        onClick={() => {
          dispatch(showDownloadDialog(job.id));
        }}
      >
        <GetAppIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};

export default DownloadAction;
