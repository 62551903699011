import Axios from "axios";

export const CheckAdmin = () => {
    return Axios.get("/api/groups/admin")
}

export const GetGroupJobs = (
    setJobs,
    user_ids = [],
   ) => {
    let user_ids_querystring = '?'
    user_ids.forEach(id => {
      user_ids_querystring += 'user_ids=' + id + '&'
    })
    return Axios.get("/api/groups/jobs" + user_ids_querystring.slice(0,-1), {
    }).then(res => {
      let jobs = res.data.jobs.map(job => {
        job.isChecked = false
        job.input_filepath = job.input_filepath ? 
          job.input_filepath.split("/").reverse()[0] : "No File"
        return job
      })
      setJobs(jobs);
    }).catch(err => {
      console.log(err)
    })
  };

export const GetGroup = (
  setUsers,
  setGroup
) => {
  return Axios.get("/api/groups/group")
  .then(res => {
    let users = res.data.users.map(user => {
      user.isChecked = false
      return user
    });
    setUsers(users);
    setGroup(res.data.group);
  })
  .catch(err => {
    console.log(err)
  });
}

export const GetLimit = (
  setLimit
) => {
  return Axios.get("/api/jobs/sub/limit")
  .then(res => {
    setLimit(res.data);
  })
  .catch(err => {
    console.log(err)
  });
}