// Slice

import { createSlice } from "@reduxjs/toolkit";
import Axios from "axios";
import { addLoad, removeLoad } from "./loading";

export const jobSlice = createSlice({
  name: "jobs",
  initialState: {
    jobs: [],
    group: null,
    jobsEmpty: 0,
  },
  reducers: {
    setJobs: (state, action) => {
      state.jobs = action.payload;
      if (state.jobsEmpty === 0 && state.jobs.length === 0) {
        state.jobsEmpty = 1;
      }
    },
    appendJobs: (state, action) => {
      state.jobs.push(...action.payload);
    },
    setGroupUsers: (state, action) => {
      let groupMap = {};
      if (action.payload) {
        action.payload.forEach((user) => {
          groupMap[user.id] = user.username;
        });
        state.group = groupMap;
      }
    },
    updateJobs: (state, action) => {
      let message = action.payload;
      let jobs = state.jobs;
      let jobIndex = jobs.findIndex((job) => job.id === message.id);

      if (jobIndex !== -1) {
        if (message.status) {
          jobs[jobIndex].status = message.status;
        }
        if (message.filename) {
          jobs[jobIndex].filename = message.filename;
        }
        if (message.error_message) {
          jobs[jobIndex].error = message.error_message;
        }
      }

      state.jobs = jobs;
    },
    jobsEmptyFalse: (state, action) => {
      state.jobsEmpty = 2;
    },
  },
});

export default jobSlice.reducer;

// Actions

const { setJobs, appendJobs, setGroupUsers, updateJobs, jobsEmptyFalse } =
  jobSlice.actions;

export const getJobs =
  (isAdmin = false) =>
  (dispatch) => {
    let url = isAdmin ? "/api/groups/jobs" : "/api/jobs";
    if (isAdmin) {
      dispatch(getAdminJobs());
      return;
    }
    dispatch(addLoad("jobfetch"));
    Axios.get(url)
      .then((res) => {
        dispatch(setJobs(res.data.jobs));
        dispatch(setGroupUsers(res.data.group));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(removeLoad());
      });
  };

const getAdminJobs = () => (dispatch) => {
  let url = "/api/groups/jobs";
  dispatch(addLoad("jobfetch"));
  Axios.get(url + "/initial")
    .then((res) => {
      dispatch(setJobs(res.data.jobs));
      dispatch(setGroupUsers(res.data.group));
      dispatch(removeLoad());
      return Axios.get(url);
    })
    .then((res) => {
      dispatch(appendJobs(res.data.jobs));
    })
    .catch((err) => {
      console.log(err);
      dispatch(removeLoad());
    });
};

export const setJobsEmptyFalse = () => (dispatch) => {
  dispatch(jobsEmptyFalse());
};

export const handleJobMessage = (message) => (dispatch) => {
  dispatch(updateJobs(message));
};

export const updateRows = () => {};
