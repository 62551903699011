import Axios, { post } from "axios";

export const GetJobs = (setJobs) => {
  return Axios.get("/api/jobs/", {}).then((res) => {
    let jobs = res.data.jobs.map((job) => {
      job.isChecked = false;
      job.input_filepath = job.input_filepath
        ? job.input_filepath.split("/").reverse()[0]
        : "No File";
      return job;
    });
    setJobs(jobs);
  });
};
export const GetJob = (id, setJob) => {
  return Axios.get(`/api/jobs/${id}`)
    .then((res) => {
      setJob(res.data.job);
    })
    .catch((err) => {
      console.log(err);
    });
};
export const DeleteJob = (JobId) => {
  return Axios.delete("/api/jobs/" + JobId);
};
export const BatchDeleteJobs = (job_ids) => {
  return Axios.delete("/api/jobs/batch/delete", {
    data: {
      job_ids: job_ids,
    },
  });
};
export const CreateJob = (
  name,
  input_files,
  floor_performance,
  waste_prioritisation,
  construction_time_prioritisation,
  num_agents,
  num_generations,
) => {
  const formData = new FormData();
  formData.append("name", name);
  formData.append("floor_performance", floor_performance);
  formData.append("waste_prioritisation", waste_prioritisation);
  formData.append(
    "construction_time_prioritisation",
    construction_time_prioritisation
  );
  formData.append("num_agents", num_agents);
  formData.append("num_generations", num_generations);
  input_files.forEach((file, index) => {
    formData.append("input_files", file);
  });
  return post("/api/jobs/", formData, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
};
export const RunJob = (
  job_ids,
  floor_performance,
  waste_prioritisation,
  construction_time_prioritisation,
  num_workers,
  num_agents,
  num_generations,
  config_json
) => {
  let job_ids_querystring = "?";
  job_ids.forEach((id) => {
    job_ids_querystring += "job_ids=" + id + "&";
  });
  const formData = new FormData();
  formData.append("floor_performance", floor_performance);
  formData.append("waste_prioritisation", waste_prioritisation);
  formData.append(
    "construction_time_prioritisation",
    construction_time_prioritisation
  );
  formData.append("num_workers", num_workers);
  formData.append("num_agents", num_agents);
  formData.append("num_generations", num_generations);
  formData.append("config_json", config_json);

  return post("/api/jobs/run" + job_ids_querystring.slice(0, -1), formData, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
};
export const SaveJob = (name, input_files) => {
  const formData = new FormData();
  formData.append("name", name);
  input_files.forEach((file, index) => {
    formData.append("input_files", file);
  });
  return post("/api/jobs/save", formData, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
};
export const CreateEmptyJob = (
  name,
  designer,
  client,
  project,
  house_type,
  floor_level
) => {
  const formData = new FormData();
  formData.append("name", name);
  formData.append("designer", designer);
  formData.append("client", client);
  formData.append("project", project);
  formData.append("house_type", house_type);
  formData.append("floor_level", floor_level);
  return post("/api/jobs/empty", formData);
};
export const CloneJob = (id, name, current) => {
  return post(
    "/api/jobs/clone?job_id=" + id + "&name=" + name + "&current=" + current
  );
};
export const CreateRevision = (id) => {
  return post("/api/revisions/create?job_id=" + id);
};
export const GetWebsocketUrl = () => {
  return Axios.get("/api/jobs/wsurl");
};

export const GetUploadLimit = () => {
  return Axios.get("/api/jobs/limit/");
};

export const GetAutoFloorUrl = (id, input, preset) => {
  return Axios.get("/api/autofloor/url?id=" + id + "&input=" + input + "&preset=" + preset);
};

export const GetDefaultDaisyCadUrl = () => {
  return Axios.get("/api/autofloor/url/default");
};

export const GetFiletypes = (job_ids, setFileypes) => {
  let job_ids_querystring = "?";
  job_ids.forEach((id) => {
    job_ids_querystring += "job_ids=" + id + "&";
  });

  return Axios.get("/api/jobs/filetypes/" + job_ids_querystring.slice(0, -1))
    .then((res) => {
      setFileypes(res.data.filetypes);
    })
    .catch((err) => console.log(err));
};
