import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { GetAutoFloorUrl, GetJob } from "../../services/Jobs";
import { RunRedesign } from "../../services/Revisions";
import { getErrors, saveJob } from "../../store/features/cad";
import { showConfirmSaveDialog } from "../../store/features/dialogs";
import DialogController from "../Dialogs/DialogController";
import ActionBar from "./ActionBar";
import { Box, makeStyles } from "@material-ui/core";

const AUTOSAVE_TIME = 300000;

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function debounce(fn, ms) {
  let timer;
  return (_) => {
    clearTimeout(timer);
    timer = setTimeout((_) => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    width: '100%',
  },
  iframe: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    border: 'none',
  },
}));

const DaisyCAD = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [Job, setJob] = useState(null);
  const [CadUrl, setCadUrl] = useState(null);
  const [CadFrame, setCadFrame] = useState(null);
  const [Height, setHeight] = useState(window.innerHeight - 104);
  const [EnableSave, setEnableSave] = useState(false);
  const [EnableRun, setEnableRun] = useState(false);
  const [EnableRedesign, setEnableRedesign] = useState(false);
  const { id } = useParams();
  const query = useQuery();
  const input = query.get("input");
  const preset = query.get("preset")
  const { running } = useSelector(state => state.cad)

  useEffect(() => {
    const interval = setInterval(() => {
      if (running && Job && !Job.is_complete) {
        dispatch(saveJob(true))
      }
    }, 10000);

    return () => clearInterval(interval)
  }, [Job, running, dispatch]);

  useEffect(() => {
    const debouncedResize = debounce(function handleResize() {
      setHeight(window.innerHeight - 104)
    }, 1000);

    debouncedResize();
    window.addEventListener("resize", debouncedResize);

    return () => {
      window.removeEventListener("resize", debouncedResize);
    };
  }, []);

  const redesignJob = () => {
    RunRedesign(Job.id);
    setEnableRedesign(false);
    navigate(`/jobs/view/${Job.id}`)
  };

  const runJob = () => {
    dispatch(showConfirmSaveDialog(id));
  };

  useEffect(() => {
    GetAutoFloorUrl(id, input, preset).then((res) => {
      setCadUrl(res.data.url);
    });
    GetJob(id, setJob);
  }, [id, input]);

  useEffect(() => {
    if (Job && Job.status === "WIP") {
      if (Job.is_revision) {
        setEnableSave(true);
        setEnableRedesign(true);
      } else {
        setEnableRun(true);
        setEnableSave(true);
      }
    }
  }, [Job]);

  useEffect(() => {
    if (Job && !Job.is_complete) {
      const interval = setInterval(() => {
        saveJob();
      }, AUTOSAVE_TIME);

      return () => clearInterval(interval);
    }
  }, [CadFrame, Job]);

  return (
    <>
      <DialogController />
      {input && (
        <ActionBar
          job={Job}
          enableSave={EnableSave}
          enableRedesign={EnableRedesign}
          redesignJob={redesignJob}
          enableRun={EnableRun}
          runJob={runJob}
        />
      )}
      <Box className={classes.container} height={Height}>
        <iframe
          className={classes.iframe}
          title="cad"
          ref={(e) => {
            setCadFrame(e);
          }}
          src={CadUrl}
        />
      </Box>
    </>
  );
};

export default DaisyCAD;
