import {
  FilteringState,
  GroupingState,
  IntegratedFiltering,
  IntegratedGrouping,
  IntegratedSelection,
  IntegratedSorting,
  SelectionState,
  SortingState,
} from "@devexpress/dx-react-grid";
import {
  TableHeaderRow,
  Grid,
  TableColumnResizing,
  TableColumnReordering,
  TableColumnVisibility,
  ColumnChooser,
  Toolbar,
  TableFixedColumns,
  VirtualTable,
  TableSelection,
  TableFilterRow,
  GroupingPanel,
  TableGroupRow,
} from "@devexpress/dx-react-grid-material-ui";
import { makeStyles, Paper } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateFilters,
  updateGrouping,
  updateHidden,
  updateOrder,
  updateSelected,
  updateWidths,
} from "../../store/features/dashboard";
import JobTableToolbar from "./_JobTableToolbar";
import TableProviders from "./TableProviders";
import TableCell from "./TableCell";

const useStyles = makeStyles((theme) => ({
  body: {
    flex: "1 1 100%",
    minHeight: 0,
    margin: theme.spacing(2),
    marginTop: theme.spacing(0),
  },
  tableCell: {
    backgroundColor: '#CCCCC',
  }
}));

function debounce(fn, ms) {
  let timer;
  return (_) => {
    clearTimeout(timer);
    timer = setTimeout((_) => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

export const JobTable = () => {
  const classes = useStyles();

  const { jobs } = useSelector((state) => state.jobs);
  const { table, selected } = useSelector((state) => state.dashboard);
  const dispatch = useDispatch();

  const rows = jobs;

  const leftColumns = [TableSelection.COLUMN_TYPE, "id"];
  const rightColumns = ["actions"];

  const columnExtensions = [
    { columnName: "id", align: "right" },
    { columnName: "parent", align: "right" },
    { columnName: "name", align: "left" },
    { columnName: "filename", align: "left" },
    { columnName: "created_at", align: "right" },
    { columnName: "updated", align: "right" },
    { columnName: "username", align: "left" },
    { columnName: "status", align: "left" },
    { columnName: "actions", align: "left" },
    { columnName: "client", align: "left" },
    { columnName: "project", align: "left" },
    { columnName: "house_type", align: "left" },
    { columnName: "floor_level", align: "left" },
    { columnName: "revisions", align: "right" },
  ];

  const minWidth = [
    { columnName: "actions", minWidth: 250 }
  ]

  const filterStateExtensions = [
    { columnName: "actions", filteringEnabled: false },
  ];
  const groupingStateExtensions = [
    { columnName: "id", groupingEnabled: false },
    { columnName: "name", groupingEnabled: false },
    { columnName: "actions", groupingEnabled: false },
  ];

  const [height, setHeight] = useState(50);

  useEffect(() => {
    const debouncedResize = debounce(function handleResize() {
      setHeight(window.innerHeight - 280)
    }, 1000);

    debouncedResize();
    window.addEventListener("resize", debouncedResize);

    return () => {
      window.removeEventListener("resize", debouncedResize);
    };
  }, []);

  return (
    <div className={classes.body}>
      <Paper>
        <Grid rows={rows} columns={table.columns}>
          <TableProviders />
          <SortingState
            defaultSorting={[{ columnName: "updated", direction: "desc" }]}
          />
          <SelectionState
            selection={selected}
            onSelectionChange={(selected) => dispatch(updateSelected(selected))}
          />
          <GroupingState
            grouping={table.grouping}
            onGroupingChange={(grouping) => dispatch(updateGrouping(grouping))}
            columnExtensions={groupingStateExtensions}
          />

          <FilteringState
            filters={table.filters}
            onFiltersChange={(filter) => {
              dispatch(updateFilters(filter))
              dispatch(updateSelected([]))
            }}
            columnExtensions={filterStateExtensions}
          />
          <IntegratedFiltering />
          <IntegratedSelection />
          <IntegratedSorting />
          <IntegratedGrouping />

          <VirtualTable
            height={height}
            cellComponent={TableCell}
            columnExtensions={columnExtensions}
          />

          <TableColumnReordering
            order={table.columnsOrder}
            onOrderChange={(order) => dispatch(updateOrder(order))}
          />
          <TableColumnResizing
            columnWidths={table.columnsWidth}
            onColumnWidthsChange={(sorting) => dispatch(updateWidths(sorting))}
            resizingMode={"widget"}
            columnExtensions={minWidth}
          />
          <TableHeaderRow showSortingControls />
          <TableSelection showSelectAll highlightRow />
          <TableFilterRow showFilterSelector />
          <TableGroupRow />
          <TableColumnVisibility
            hiddenColumnNames={table.columnsHidden}
            onHiddenColumnNamesChange={(hidden) =>
              dispatch(updateHidden(hidden))
            }
          />
          <TableFixedColumns
            leftColumns={leftColumns}
            rightColumns={rightColumns}
          />
          <Toolbar />
          <GroupingPanel showGroupingControls showSortingControls />
          <ColumnChooser />
          <JobTableToolbar />
        </Grid>
      </Paper>
    </div>
  );
};
