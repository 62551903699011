import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchLimits } from "../../store/features/limits";
import { showExpiredDemoDialog } from "../../store/features/dialogs";
import DialogController from "../Dialogs/DialogController";
import { JobTable } from "./JobTable";
import JobHeader from "./JobHeader";
import { getTable, updateColumns } from "../../store/features/dashboard";
import { setAdminView, setUserId } from "../../store/features/user";
import { CheckAdmin } from "../../services/Admin";
import { getJobs, handleJobMessage } from "../../store/features/jobs";
import { GetDefaultDaisyCadUrl, GetWebsocketUrl } from "../../services/Jobs";
import Loading from "../Shared/Layout/Loading";

let websocket;

const Jobs = () => {
  const dispatch = useDispatch();
  const { remaining_jobs, level } = useSelector((state) => state.limits);
  const { admin } = useSelector((state) => state.user);
  const [CadUrl, setCadUrl] = useState(null);

  useEffect(() => {
    dispatch(fetchLimits());
    dispatch(getTable());
    GetDefaultDaisyCadUrl().then((res) => {
      let r = (Math.random() + 1).toString(36).substring(7);
      setCadUrl(res.data.url + "?" + r);
    });
  }, [dispatch]);

  useEffect(() => {
    if (admin !== null) {
      dispatch(getJobs(admin));
      dispatch(updateColumns(admin));
    }
  }, [admin, dispatch]);

  useEffect(() => {
    if (remaining_jobs === 0 && level === 1) {
      dispatch(showExpiredDemoDialog());
    }
  }, [remaining_jobs, level, dispatch]);

  useEffect(() => {
    CheckAdmin()
    .then((res) => {
      dispatch(setAdminView(res.data.admin));
    })
    .catch((err) => {
      console.log(err);
    });
  }, [dispatch]);

  useEffect(() => {
    GetWebsocketUrl()
      .then((res) => {
        websocket = new WebSocket(res.data.websocket_url);
        websocket.onopen = (event) => {
          websocket.send(localStorage.getItem("jwt_token"));
        };
        websocket.onmessage = (event) => {
          let message = JSON.parse(event.data);
          dispatch(handleJobMessage(message));
        };
      })
      .catch((err) => {
        console.log(err);
      });

    return function cleanup() {
      if (websocket) {
        websocket.close();
      }
    };
  }, [dispatch]);

  return (
    <>
      <DialogController />
      <Loading />
      <JobHeader />
      <JobTable />
      <iframe title='cad' id="cad" src={CadUrl} hidden />
    </>
  );
};

export default Jobs;
