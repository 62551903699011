import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import StarIcon from '@material-ui/icons/Star';

import { hideDialog } from '../../store/features/dialogs';

function ExpiredDemoDialog(props) {

  const { dialog_type } = useSelector((state) => state.dialogs)
  const dispatch = useDispatch();

  return (
    <Dialog 
      maxWidth='sm'
      fullWidth
      open={dialog_type === 'expired'}
    >
      <DialogTitle>
        Upgrade Account
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          Your demo has ended. Please upgrade your account.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button 
          variant='contained'
          onClick={() => {
            dispatch(hideDialog())
          }}
        >
          Hide
        </Button>
        <Button 
          variant='contained'
          onClick={() => {                        
            dispatch(hideDialog())
          }}
          startIcon={<StarIcon style={{ color: "#1F5C86" }} />}
          to='/stripe/preview'
          component={Link}
        >
          Upgrade Account
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ExpiredDemoDialog;