import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { saveJob, setEmptyErrors } from "../../store/features/cad";
import { hideDialog, showRunDialog } from "../../store/features/dialogs";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import WarningIcon from '@material-ui/icons/Warning';
import Loading from "../Shared/Layout/Loading";

function ConfirmSaved() {
  const { dialog_type, value } = useSelector((state) => state.dialogs);
  const { errors, pendingErrors } = useSelector((state) => state.cad);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (dialog_type === 'confirmsave') {
      dispatch(saveJob(true))
    }
  }, [dialog_type, dispatch])

  return (
    <Dialog maxWidth="sm" fullWidth open={dialog_type === "confirmsave"}>
      <Loading />
      {(errors.critical.length > 0) && (
        <DialogTitle>Your Job contains critical errors.</DialogTitle>
      )}
      {(errors.critical.length === 0) && (errors.warnings.length > 0) && (
        <DialogTitle>Your Job contains errors.</DialogTitle>
      )}
      {(errors.critical.length === 0) && (errors.warnings.length === 0) && (
        <DialogTitle>Your Job is ready to run.</DialogTitle>
      )}
      <DialogContent dividers>
        <DialogContentText>
          {(errors.critical.length > 0) && (
            <>
              Your job contains errors that will cause it to fail.
              Please go to the Check Project tab and fix them.
            </>
          )}
          {(errors.critical.length === 0) && (errors.warnings.length > 0) && (
            <>
              Your job contains errors that may cause it to fail.
              You can choose to ignore them or go to the Check Project tab to fix them.
            </>
          )}
          {(errors.critical.length === 0) && (errors.warnings.length === 0) && (
            <>
              Click next to get DAISY to produce your design.
            </>
          )}
          {/* <List>
            {errors.critical.length > 0 && (
              <Typography variant='h6'>Critical Errors</Typography>
            )}
            {errors.critical.map(err => (
              <ListItem>
                <ListItemIcon>
                  <ErrorOutlineIcon style={{ color: "#990000" }} />
                </ListItemIcon>
                <ListItemText primary={err} />
              </ListItem>
            ))}
          </List> */}
          {/* <List>
            {errors.warnings.length > 0 && (
              <Typography variant='h6'>Warnings</Typography>
            )}
            {errors.warnings.map(err => (
              <ListItem>
                <ListItemIcon>
                  <WarningIcon style={{ color: "#eed202" }} />
                </ListItemIcon>
                <ListItemText primary={err} />
              </ListItem>
            ))}
          </List> */}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            dispatch(hideDialog());
            dispatch(setEmptyErrors([]))
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          hidden={
            (errors.critical.length == 0 &&
              errors.warnings.length == 0) ||
            location.pathname.includes('cad')
          }
          onClick={() => {
            navigate(`/jobs/cad/${value}?input=${true}`)
            dispatch(hideDialog());
          }}
        >
          Edit Job
        </Button>
        <Button
          variant="contained"
          disabled={errors.critical.length > 0}
          onClick={() => {
            dispatch(hideDialog());
            dispatch(setEmptyErrors([]))
            dispatch(showRunDialog([value]));
          }}
        >
          {(errors.critical.length > 0) && (
            'Next'
          )}
          {(errors.critical.length === 0) && (errors.warnings.length > 0) && (
            'Ignore Warnings'
          )}
          {(errors.critical.length === 0) && (errors.warnings.length === 0) && (
            'Next'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmSaved;
