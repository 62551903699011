//Slice

import { createSlice } from '@reduxjs/toolkit'

export const loadingSlice = createSlice({
  name: 'loading',
  initialState: {
    general: [],
    modal: false,
  },
  reducers: {
    pushGeneral: (state, action) => {
      state.general.push(action.payload.type);
    },
    popGeneral: (state, action) => {
      state.general.pop();
    },
    setModalLoad: (state, action) => {
      state.modal = action.payload;
    },

  },
})

export default loadingSlice.reducer

// Actions

const { pushGeneral, popGeneral, setModalLoad } = loadingSlice.actions

export const addLoad = (type) => dispatch => {
  console.log('addLoad', type)
  dispatch(pushGeneral(type));
}

export const removeLoad = () => dispatch => {
  dispatch(popGeneral());
}

export const modalLoading = () => dispatch => {
  dispatch(setModalLoad(true));
}

export const modalDoneLoading = () => dispatch => {
  dispatch(setModalLoad(false));
}