import { cadSlice, getErrors, saveJob } from "./features/cad";
import { removeLoad } from "./features/loading";


const addMessageListener = (dispatch, getState) => {
    window.addEventListener("message", e => {
        let state = getState()
        switch (e.data.message) {
            case 'daisyCad.errorsResult':
                dispatch(cadSlice.actions.setErrors(e.data.errors));
                if (state.cad.pendingSave) {
                    dispatch(cadSlice.actions.setPendingSave(false))
                    if (e.data.errors.length === 0) {
                        dispatch(saveJob(true))
                    }
                }
                break;
            case 'daisyCad.running':
                dispatch(cadSlice.actions.setRunning(true))
                break;
            case 'daisyCad.hasChanges':
                if (state.cad.pendingErrors) {
                    dispatch(cadSlice.actions.setPendingErrors(false))
                    dispatch(removeLoad())
                    dispatch(getErrors())
                }
            case 'daisyCad.loadSuccess':
                if (state.cad.pendingErrors) {
                    dispatch(cadSlice.actions.setPendingErrors(false))
                    dispatch(removeLoad())
                    dispatch(getErrors())
                }
            case 'daisyCad.loadFail':
                if (state.cad.pendingErrors) {
                    dispatch(cadSlice.actions.setPendingErrors(false))
                    dispatch(removeLoad())
                }
            default:
                break;
        }
    })
}

export default addMessageListener;