import axios from "axios";

export const checkout = (price_id) => {
  axios
    .get("/api/stripe/checkout?price_id=" + price_id)
    .then((res) => {
      window.location.href = res.data.url;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const portal = () => {
  axios
    .get("/api/stripe/portal")
    .then((res) => {
      window.location.href = res.data.url;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const get_products = (setProducts) => {
  axios.get("/api/stripe/products").then((res) => {
    setProducts(res.data);
  });
};
