import { IconButton, Tooltip } from "@material-ui/core";
import React from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { CreateRevision } from "../../../services/Jobs";
import { addLoad, removeLoad } from "../../../store/features/loading";
import { showEditDialog } from "../../../store/features/dialogs";

const useStyles = makeStyles((theme) => ({
  tableCell: {
    // padding: theme.spacing(-1)
  },
  icon: {
    "& svg": {
      fontSize: 2,
    },
  },
  button: {
    maxHeight: 25,
  },
}));

const NewRevisionAction = ({ job }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <Tooltip title="Create Revision ">
      <IconButton
        className={classes.button}
        color="primary"
        variant="outlined"
        size="small"
        onClick={() => {
          dispatch(addLoad("revision"));
          CreateRevision(job.id)
            .then((res) => {
              dispatch(showEditDialog(res.data.job_id, false));
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              dispatch(removeLoad());
            });
        }}
      >
        <EditOutlinedIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};

export default NewRevisionAction;
