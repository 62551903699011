import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { hideDialog } from '../../store/features/dialogs';
import { getJobs } from '../../store/features/jobs';

function EditDialog() {

  const { dialog_type, value } = useSelector((state) => state.dialogs)
  const { admin } = useSelector((state) => state.user)
  const dispatch = useDispatch();

  return (
    <Dialog 
      maxWidth='sm'
      fullWidth
      open={dialog_type === 'edit'}
    >
      <DialogTitle>
        Redirecting
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          Redirecting To Daisy-CAD...
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button 
          variant='contained'
          onClick={() => {
            dispatch(getJobs(admin))
            dispatch(hideDialog())
          }}
        >
          Cancel
        </Button>
        <Button 
          variant='contained'
          component={Link}
          to={`/jobs/cad/${value.id}?input=${value.input}`} 
          onClick={() => {
            dispatch(getJobs(admin))             
            dispatch(hideDialog())
          }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditDialog;